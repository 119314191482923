import React, { memo } from 'react';

const JsonEditor = memo(({ data, onChange, path = [] }) => {
  const isArrayOfObjects = (arr) => {
    return Array.isArray(arr) && arr.length > 0 && typeof arr[0] === 'object';
  };

  const renderField = (key, value, currentPath) => {
    const newPath = [...currentPath, key];
    
    if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
      return (
        <div key={key} className="mb-4">
          <h3 className="text-lg font-semibold mb-2 text-gray-700">{key.replace(/_/g, ' ').toUpperCase()}</h3>
          <div className="pl-4 border-l-2 border-gray-200">
            {Object.entries(value).map(([subKey, subValue]) => renderField(subKey, subValue, newPath))}
          </div>
        </div>
      );
    } else if (isArrayOfObjects(value)) {
      return (
        <div key={key} className="mb-6">
          <h2 className="text-xl font-bold mb-4 text-gray-800">{key.replace(/_/g, ' ').toUpperCase()}</h2>
          {value.map((item, index) => (
            <div key={index} className="mb-4 p-4 bg-gray-50 rounded-lg">
              {Object.entries(item).map(([subKey, subValue]) => 
                renderField(subKey, subValue, [...newPath, index])
              )}
            </div>
          ))}
        </div>
      );
    } else if (Array.isArray(value)) {
      return (
        <div key={key} className="mb-4">
          <h3 className="text-lg font-semibold mb-2 text-gray-700">{key.replace(/_/g, ' ').toUpperCase()}</h3>
          {value.map((item, index) => (
            <div key={index} className="pl-4 mb-2 border-l-2 border-gray-200">
              {renderField(`${index}`, item, newPath)}
            </div>
          ))}
        </div>
      );
    } else {
      const isLongText = typeof value === 'string' && value.length > 100;
      return (
        <div key={key} className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            {key.replace(/_/g, ' ').charAt(0).toUpperCase() + key.replace(/_/g, ' ').slice(1)}
          </label>
          {isLongText ? (
            <textarea
              value={value || ''}
              onChange={(e) => onChange(newPath, e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              rows={Math.ceil(value.length / 50)}
            />
          ) : (
            <input
              type="text"
              value={value || ''}
              onChange={(e) => onChange(newPath, e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          )}
        </div>
      );
    }
  };

  const renderCV = (cvData) => {
    return Object.entries(cvData).map(([key, value]) => {
      if (key === 'design') return null; // Skip the design section
      return renderField(key, value, []);
    });
  };

  return (
    <div className="space-y-6">
      {renderCV(data)}
    </div>
  );
});

export default JsonEditor;