import React, { useState, useEffect, useCallback } from "react";
import Navbar from "../navbar/Navbar";
import { useNotification } from "../../NotificationContext";
import { useNavigate } from 'react-router-dom';
import api from '../../utils/api';
import WelcomeBannerDashboard from "../banners/WelcomeBannerDashboard";

function JobDashboard() {
  const [jobs, setJobs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [expandedJobId, setExpandedJobId] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(undefined);
  const { showNotification } = useNotification();
  const [userName, setUserName] = useState("");
  const [appliedJobs, setAppliedJobs] = useState([]);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const [jobMatches, setJobMatches] = useState({});
  const [loadingMatches, setLoadingMatches] = useState({});
  const [expandedMatchDetails, setExpandedMatchDetails] = useState(null);
  const [showMatchButtons, setShowMatchButtons] = useState(false);
  const navigate = useNavigate();

  // Initialize auth state only once
  useEffect(() => {
    const token = localStorage.getItem("token");
    const user = JSON.parse(localStorage.getItem("user") || "null");
    setIsLoggedIn(!!token);
    if (user?.name) {
      setUserName(capitalizeFirstLetter(user.name));
    }
  }, []);

  // Check subscription status when auth state changes
  useEffect(() => {
    const fetchSubscriptionStatus = async () => {
      const token = localStorage.getItem("token");
      if (!token || !isLoggedIn) return;

      try {
        const response = await api.get("/auth/profile", {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (response.data.subscriptionStatus === "active") {
          setIsSubscribed(true);
        }
      } catch (error) {
        console.error("Error fetching subscription status:", error);
        if (error.response?.status === 401) {
          setIsLoggedIn(false);
          localStorage.removeItem("token");
        }
      }
    };

    if (isLoggedIn !== undefined) {
      fetchSubscriptionStatus();
    }
  }, [isLoggedIn]);

  // Fetch applied jobs when logged in
  useEffect(() => {
    const fetchAppliedJobs = async () => {
      const token = localStorage.getItem("token");
      if (!token || !isLoggedIn) return;

      try {
        const response = await api.get("/user/applied-jobs", {
          headers: { Authorization: `Bearer ${token}` },
        });
        setAppliedJobs(response.data.appliedJobs.map((job) => job.job_id));
      } catch (error) {
        console.error("Error fetching applied jobs:", error);
      }
    };

    if (isLoggedIn) {
      fetchAppliedJobs();
    }
  }, [isLoggedIn]);

  // Load cached data on mount
  useEffect(() => {
    const loadCachedData = () => {
      const cachedJobs = localStorage.getItem('cachedJobs');
      const cachedSearchQuery = localStorage.getItem('cachedSearchQuery');
      const cachedCurrentPage = localStorage.getItem('cachedCurrentPage');
      const cachedTotalPages = localStorage.getItem('cachedTotalPages');
    
      if (cachedJobs && cachedSearchQuery) {
        const parsedJobs = JSON.parse(cachedJobs);
        setJobs(parsedJobs);
        
        if (!searchQuery) {
          setSearchQuery(cachedSearchQuery);
        }
        setCurrentPage(parseInt(cachedCurrentPage, 10) || 1);
        setTotalPages(parseInt(cachedTotalPages, 10) || 1);
        
        setTimeout(() => {
          setShowMatchButtons(true);
        }, 1000);
      }
    };

    loadCachedData();
  }, []);

  const analyzeJobMatch = async (jobId, jobDescription) => {
    if (!isLoggedIn) return;
    
    setLoadingMatches(prev => ({ ...prev, [jobId]: true }));
    try {
      const response = await api.post('/manual/analyze-match', {
        jobDescription
      });

      setJobMatches(prev => ({
        ...prev,
        [jobId]: {
          score: response.data.score,
          reasons: response.data.reasons
        }
      }));
    } catch (error) {
      console.error('Error analyzing job match:', error);
      showNotification('Error analyzing job match', 'error');
    } finally {
      setLoadingMatches(prev => ({ ...prev, [jobId]: false }));
    }
  };

  const fetchJobs = useCallback(async () => {
    if (!searchQuery || isLoading) return;

    try {
      setIsLoading(true);
      setExpandedMatchDetails(null);
      setShowMatchButtons(false);
      
      const response = await api.get("/jobs", {
        params: {
          query: searchQuery,
          page: currentPage,
          num_pages: 1,
        },
      });

      const newJobs = response.data.data;
      setJobs(newJobs);
      setTotalPages(response.data.total_pages);

      localStorage.setItem('cachedJobs', JSON.stringify(newJobs));
      localStorage.setItem('cachedSearchQuery', searchQuery);
      localStorage.setItem('cachedCurrentPage', currentPage);
      localStorage.setItem('cachedTotalPages', response.data.total_pages);

      setTimeout(() => {
        setShowMatchButtons(true);
      }, 1000);
    } catch (error) {
      console.error("Error fetching jobs:", error);
      showNotification("Error fetching jobs", "error");
    } finally {
      setIsLoading(false);
    }
  }, [searchQuery, currentPage, isLoading, showNotification]);

  const handleSearch = useCallback(() => {
    if (!searchQuery.trim()) {
      showNotification("Please enter a search query", "error");
      return;
    }
    setCurrentPage(1);
    setJobMatches({});
    setExpandedMatchDetails(null);
    localStorage.setItem('cachedSearchQuery', searchQuery);
    localStorage.removeItem('cachedJobs');
    localStorage.removeItem('cachedCurrentPage');
    localStorage.removeItem('cachedTotalPages');
    fetchJobs();
  }, [searchQuery, fetchJobs, showNotification]);

  const handlePageChange = useCallback((pageNumber) => {
    if (!isLoggedIn) {
      showNotification("Please login or register to view more jobs", "error");
      return;
    }
    setCurrentPage(pageNumber);
    setJobMatches({});
    setExpandedMatchDetails(null);
    setShowMatchButtons(false);
    localStorage.setItem('cachedCurrentPage', pageNumber);
    window.scrollTo({ top: 0, behavior: 'smooth' });
    fetchJobs();
  }, [isLoggedIn, fetchJobs, showNotification]);

  const handleViewDetails = (jobId, event) => {
    event.preventDefault();
    event.stopPropagation();
    
    window.open(`/job-details/${jobId}`, '_blank');
    setSelectedJob({ job_id: jobId });
    setShowDialog(true);
  };

  const handleApplyConfirm = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token || !selectedJob) {
        showNotification("Please log in to apply for jobs", "error");
        return;
      }

      await api.post(
        "/user/apply-job",
        { job_id: selectedJob.job_id },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      setAppliedJobs(prev => [...prev, selectedJob.job_id]);
      showNotification("Job added to the tracker", "success");
      setShowDialog(false);
      setSelectedJob(null);
    } catch (error) {
      console.error("Error applying for job:", error);
      const errorMessage = error.response?.data?.error || "Error applying for job";
      showNotification(errorMessage, "error");
    }
  };

  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  const toggleMatchDetails = (jobId) => {
    setExpandedMatchDetails(expandedMatchDetails === jobId ? null : jobId);
  };

  const getMatchScoreColor = (score) => {
    if (score >= 80) return {
      bg: 'bg-green-100',
      text: 'text-green-600',
      border: 'border-green-200',
      hover: 'hover:bg-green-200'
    };
    if (score >= 60) return {
      bg: 'bg-yellow-100',
      text: 'text-yellow-600',
      border: 'border-yellow-200',
      hover: 'hover:bg-yellow-200'
    };
    return {
      bg: 'bg-red-100',
      text: 'text-red-600',
      border: 'border-red-200',
      hover: 'hover:bg-red-200'
    };
  };

  const truncateDescription = (description, wordLimit) => {
    const words = description.split(" ");
    if (words.length <= wordLimit) {
      return description;
    }
    return words.slice(0, wordLimit).join(" ") + "...";
  };

  const toggleDescription = (jobId, event) => {
    event.preventDefault();
    event.stopPropagation();
    setExpandedJobId((prevJobId) => (prevJobId === jobId ? null : jobId));
  };
  return (
    <>
      <Navbar />
      <div className="pt-20 px-4 md:px-0">
        <WelcomeBannerDashboard isLoggedIn={isLoggedIn} userName={userName} />
        <div className="flex outline-none rounded-lg mx-auto mt-10 max-w-md">
          <input
            type="text"
            placeholder="For example: Marketing Executive"
            className="w-full outline-none bg-white text-gray-600 text-sm px-4 py-3 rounded-l-lg border-l border-t border-b focus:ring-blue-500"
            value={searchQuery}
            onChange={(e) => {
              const newValue = e.target.value;
              setSearchQuery(newValue);
              if (newValue === '') {
                localStorage.removeItem('cachedSearchQuery');
              }
            }}
          />
          <button
            type="button"
            className="flex items-center justify-center bg-blue-600 hover:bg-blue-700 px-5 text-sm text-white rounded-r-lg transition-colors duration-200"
            onClick={handleSearch}
            disabled={isLoading}
          >
            {isLoading ? (
              <span className="flex items-center">
                <svg className="animate-spin -ml-1 mr-2 h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Searching...
              </span>
            ) : "Search"}
          </button>
        </div>
        {isLoading ? (
          <div className="flex flex-col items-center mt-10">
            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-600 mb-4"></div>
            <p className="text-gray-600 animate-pulse font-bold">
              Loading your Visa Sponsored Jobs
            </p>
          </div>
        ) : jobs.length === 0 ? (
          <div className="flex flex-col justify-center items-center mt-10 animate-fade-in">
            <p className="text-gray-600 mx-4 font-semibold text-center">Just enter your desired job role in the search bar above and see the magic ....🚀</p>
            <a className="pt-5 underline text-indigo-600 hover:text-indigo-800 transition-colors duration-200" href="/applied-jobs">View Previously Applied Jobs</a>
          </div>
        ) : (
          <div className="space-y-6 mt-10">
            {jobs.map((job, index) => (
              <div
                key={job.job_id}
                className={`mx-auto max-w-screen-md overflow-hidden rounded-lg border bg-white shadow-md hover:shadow-lg transition-all duration-300 transform hover:-translate-y-1 animate-fade-in`}
                style={{ animationDelay: `${index * 100}ms` }}
              >
                <div className="p-6">
                  <div className="flex flex-col md:flex-row md:items-start gap-4">
                    <div className="flex-shrink-0 w-16 h-16">
                      <div className="relative w-full h-full overflow-hidden rounded-lg">
                        <img
                          src={job.employer_logo}
                          alt={job.employer_name}
                          className="w-full h-full object-contain"
                        />
                      </div>
                    </div>

                    <div className="flex-grow">
                      <div className="flex flex-col md:flex-row md:items-center justify-between gap-2">
                        <h3 className="text-gray-600 text-sm">{job.employer_name}</h3>
                        {isLoggedIn && showMatchButtons && (
                          <div className="flex items-center gap-2">
                            {loadingMatches[job.job_id] ? (
                              <div className="flex items-center space-x-2">
                                <div className="animate-spin h-4 w-4 border-2 border-blue-500 rounded-full border-t-transparent"></div>
                                <span className="text-sm text-gray-500">Analyzing...</span>
                              </div>
                            ) : jobMatches[job.job_id] ? (
                              <div className="flex items-center space-x-2">
                                <div className={`px-3 py-1 rounded-full text-sm font-medium ${getMatchScoreColor(jobMatches[job.job_id].score).bg} ${getMatchScoreColor(jobMatches[job.job_id].score).text} transition-all duration-200`}>
                                  {jobMatches[job.job_id].score}% Match
                                </div>
                                <button
  onClick={() => toggleMatchDetails(job.job_id)}
  className={`px-2 py-1 rounded-full ${getMatchScoreColor(jobMatches[job.job_id].score).bg} ${getMatchScoreColor(jobMatches[job.job_id].score).text} hover:scale-105 transition-transform duration-200 flex items-center gap-1 text-sm`}
  title="View match details"
>
  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
  </svg>
  <span className="whitespace-nowrap">Click to see why</span>
</button>
                              </div>
                            ) : (
                              <button
  onClick={() => analyzeJobMatch(job.job_id, job.job_description)}
  className="px-3 py-1 text-sm font-medium text-blue-600 bg-blue-100 rounded-full hover:bg-blue-200 transition-colors duration-200 flex items-center gap-1.5"
>
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    className="h-4 w-4" 
    fill="none" 
    viewBox="0 0 24 24" 
    stroke="currentColor"
  >
    <path 
      strokeLinecap="round" 
      strokeLinejoin="round" 
      strokeWidth={2} 
      d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" 
    />
  </svg>
  Show Match Score
</button>
                            )}
                          </div>
                        )}
                      </div>

                      <h2 className="text-lg font-semibold mt-2 text-gray-900">{job.job_title}</h2>

                      {expandedMatchDetails === job.job_id && jobMatches[job.job_id] && (
  <div className={`mt-2 p-3 rounded-lg ${getMatchScoreColor(jobMatches[job.job_id].score).bg} ${getMatchScoreColor(jobMatches[job.job_id].score).text} animate-fade-in`}>
    <div className="font-medium mb-1">Match Analysis:</div>
    <ul className="text-sm space-y-1 list-disc pl-4">
      {jobMatches[job.job_id].reasons.map((reason, idx) => (
        <li key={idx} className="marker:text-current">{reason}</li>
      ))}
    </ul>
  </div>
)}

                      <div className="mt-2">
                        <p className="text-sm text-gray-600">
                          {expandedJobId === job.job_id
                            ? job.job_description
                            : truncateDescription(job.job_description, 50)}
                        </p>
                        {job.job_description.split(" ").length > 50 && (
                          <button
                            className="mt-2 text-sm text-blue-500 hover:text-blue-700 transition-colors duration-200"
                            onClick={(e) => toggleDescription(job.job_id, e)}
                          >
                            {expandedJobId === job.job_id ? "Show Less" : "Read More"}
                          </button>
                        )}
                      </div>

                      <div className="mt-4 flex flex-wrap items-center gap-4">
                        <div className="flex items-center">
                          <span className="text-sm font-medium text-gray-600">Sponsorship:</span>
                          <span className="ml-2 px-2 py-1 text-sm rounded-full bg-green-100 text-green-800">
                            {job.sponsorshipAvailable}
                          </span>
                        </div>

                        <div className="flex-grow"></div>

                        {isLoggedIn ? (
                          appliedJobs.includes(job.job_id) ? (
                            <button
                              onClick={(e) => handleViewDetails(job.job_id, e)}
                              className="text-white bg-gray-500 font-medium rounded-lg text-sm px-5 py-2 text-center transition-all duration-200 hover:bg-gray-600"
                            >
                              Already Applied
                            </button>
                          ) : (
                            <button
                              onClick={(e) => handleViewDetails(job.job_id, e)}
                              className="text-white bg-gradient-to-br from-blue-600 to-blue-500 hover:bg-gradient-to-bl font-medium rounded-lg text-sm px-5 py-2 text-center transition-all duration-200 hover:shadow-lg transform hover:-translate-y-0.5"
                            >
                              View Details
                            </button>
                          )
                        ) : (
                          <button
                            onClick={() => showNotification("Please log in to apply for jobs", "info")}
                            className="text-white bg-gray-500 font-medium rounded-lg text-sm px-5 py-2 text-center transition-colors duration-200"
                          >
                            View Details
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}

        {/* Custom Dialog */}
        {showDialog && (
          <div className="fixed inset-0 z-50 overflow-y-auto animate-fade-in">
            <div 
              className="fixed inset-0 bg-black bg-opacity-50 transition-opacity backdrop-blur-sm"
              onClick={() => setShowDialog(false)}
            ></div>
            
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg animate-slide-up">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <h3 className="text-lg font-semibold leading-6 text-gray-900">
                        Have you applied for this job?
                      </h3>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Click "Yes" if you have submitted your application for this position. 
                          This will add the job to your "Applied Jobs" section.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 transition-colors duration-200 sm:ml-3 sm:w-auto"
                    onClick={handleApplyConfirm}
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 transition-colors duration-200 sm:mt-0 sm:w-auto"
                    onClick={() => setShowDialog(false)}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Pagination */}
        <div className="flex flex-col items-center mt-10 mb-8">
          <span className="text-sm text-gray-700">
            Page <span className="font-semibold text-gray-900">{currentPage}</span>
          </span>
          <div className="inline-flex mt-2 xs:mt-0">
            {isLoggedIn ? (
              isSubscribed ? (
                <>
                  <button
                    className="flex items-center justify-center px-4 h-10 text-base font-medium text-white bg-gray-800 rounded-s hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1 || isLoading}
                  >
                    <svg
                      className="w-3.5 h-3.5 me-2 rtl:rotate-180"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 14 10"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M13 5H1m0 0 4 4M1 5l4-4"
                      />
                    </svg>
                    {isLoading ? "Loading..." : "Prev"}
                  </button>
                  <button
                    className="flex items-center justify-center px-4 h-10 text-base font-medium text-white bg-gray-800 border-0 border-s border-gray-700 rounded-e hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages || isLoading}
                  >
                    {isLoading ? "Loading..." : "Next"}
                    <svg
                      className="w-3.5 h-3.5 ms-2 rtl:rotate-180"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 14 10"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M1 5h12m0 0L9 1m4 4L9 9"
                      />
                    </svg>
                  </button>
                </>
              ) : (
                <div className="flex flex-col items-center">
                  <div className="filter blur-sm pointer-events-none flex">
                    <button className="flex items-center justify-center px-4 h-10 text-base font-medium text-white bg-gray-800 rounded-s">
                      Prev
                    </button>
                    <button className="flex items-center justify-center px-4 h-10 text-base font-medium text-white bg-gray-800 border-0 border-s border-gray-700 rounded-e">
                      Next
                    </button>
                  </div>

                  <a className="mt-2 text-sm font-black text-gray-600"
                    href="/subscribe"
                  >
                    Please subscribe to see all the results.
                  </a>
                </div>
              )
            ) : (
              <div className="flex flex-col items-center">
                <div className="filter blur-sm pointer-events-none flex">
                  <button className="flex items-center justify-center px-4 h-10 text-base font-medium text-white bg-gray-800 rounded-s">
                    Prev
                  </button>
                  <button className="flex items-center justify-center px-4 h-10 text-base font-medium text-white bg-gray-800 border-0 border-s border-gray-700 rounded-e">
                    Next
                  </button>
                </div>

                <a className="mt-2 text-sm font-black text-gray-600"
                  href="/login"
                >
                  Please login or Request Access to view more jobs.
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default JobDashboard;