import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import api from '../../utils/api';
import { useNotification } from "../../NotificationContext";

function UserDetails() {
  const [user, setUser] = useState(null);
  const { userId } = useParams();
  const navigate = useNavigate();
  const { showNotification } = useNotification();

  useEffect(() => {
    fetchUserDetails();
  }, [userId]);

  const fetchUserDetails = async () => {
    try {
      const response = await api.get(`/admin/users/${userId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('adminToken')}` }
      });
      setUser(response.data);
    } catch (error) {
      showNotification('Error fetching user details', 'error');
    }
  };

  const groupJobsByDate = (jobs) => {
    const grouped = jobs.reduce((acc, job) => {
      const date = new Date(job.applied_at).toLocaleDateString();
      if (!acc[date]) {
        acc[date] = 0;
      }
      acc[date]++;
      return acc;
    }, {});

    return Object.entries(grouped).map(([date, count]) => ({ date, count }));
  };

  const handleSubscriptionChange = async (newStatus) => {
    try {
      await api.put(`/admin/users/${userId}/subscription`, 
        { subscriptionStatus: newStatus },
        { headers: { Authorization: `Bearer ${localStorage.getItem('adminToken')}` }}
      );
      fetchUserDetails();
      showNotification('Subscription status updated', 'success');
    } catch (error) {
      showNotification('Error updating subscription status', 'error');
    }
  };

  if (!user) return <div>Loading...</div>;

  return (
    <div className="container mx-auto px-4 py-8">
      <button
        onClick={() => navigate('/admin/dashboard')}
        className="mb-4 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
      >
        Back to Dashboard
      </button>
      <h1 className="text-2xl font-bold mb-4">User Details</h1>
      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <div className="mb-4">
          <strong>Username:</strong> {user.username}
        </div>
        <div className="mb-4">
          <strong>Name:</strong> {user.name}
        </div>
        <div className="mb-4">
          <strong>Email:</strong> {user.email}
        </div>
        <div className="mb-4">
          <strong>Mobile Number:</strong> {user.mobileNumber}
        </div>
        <div className="mb-4">
          <strong>Subscription Status:</strong> {user.subscriptionStatus}
        </div>
        <div className="mb-4">
          <strong>Subscription Start Date:</strong> {user.subscriptionStartDate ? new Date(user.subscriptionStartDate).toLocaleDateString() : 'N/A'}
        </div>
        <div className="mb-4">
          <strong>Applied Jobs:</strong>
          <div className="mb-4">
        <strong>Applied Jobs by Date:</strong>
        <ul>
          {groupJobsByDate(user.appliedJobs).map(({ date, count }) => (
            <li key={date}>
              {date}: {count} job{count !== 1 ? 's' : ''}
            </li>
          ))}
        </ul>
      </div>
        </div>
        <div className="mt-4">
          <button
            onClick={() => handleSubscriptionChange('active')}
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mr-2"
          >
            Activate Subscription
          </button>
          <button
            onClick={() => handleSubscriptionChange('inactive')}
            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
          >
            Deactivate Subscription
          </button>
        </div>
      </div>
    </div>
  );
}

export default UserDetails;