import React, { useState, useEffect } from 'react'
import { useNavigate, Navigate } from 'react-router-dom'
import Navbar from '../navbar/Navbar'
import Footer from '../footer/Footer'
import api from '../../utils/api';
import { useNotification } from "../../NotificationContext"

function Login() {
  const [formData, setFormData] = useState({ username: '', password: '' })
  const [error, setError] = useState('')
  const navigate = useNavigate()
  const { showNotification } = useNotification();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  if (isLoggedIn) {
    return <Navigate to="/" />;
  }

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleSubmit = async (e) => {
    e.preventDefault() // Prevent default form submission
    setIsLoading(true);
    try {
      const response = await api.post('/auth/login', formData);
      localStorage.setItem('token', response.data.token)
      localStorage.setItem('user', JSON.stringify(response.data.user))
      showNotification('Login successful', 'success');
      setIsLoggedIn(true); // Set logged in state to true
    } catch (error) {
      console.error('Login error:', error); // Debug log
      showNotification('An error occurred', 'error');
      setError(error.response?.data?.error || 'An error occurred. Please try again.')
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <div className='pb-20'>
        <Navbar />
      </div>
      <div className="pt-10">
        <div className="min-h-screen flex flex-col items-center justify-center py-6 px-4">
          <div className="grid md:grid-cols-2 items-center gap-4 max-w-6xl w-full">
            <div className="border border-gray-300 rounded-lg p-6 max-w-md shadow-[0_2px_22px_-4px_rgba(93,96,127,0.2)] max-md:mx-auto">
              <form className="space-y-4" onSubmit={handleSubmit}>
                {error && <p className="text-red-500">{error}</p>}
                <div className="mb-8">
                  <h3 className="text-gray-800 text-3xl font-extrabold">Sign in</h3>
                  <p className="text-gray-500 text-sm mt-4 leading-relaxed">Sign in to your account and explore a world of possibilities. Your journey begins here.</p>
                </div>

                <div>
                  <label className="text-gray-800 text-sm mb-2 block">User name</label>
                  <div className="relative flex items-center">
                    <input name="username" type="text" required className="w-full text-sm text-gray-800 border border-gray-300 px-4 py-3 rounded-lg outline-blue-600" placeholder="Enter user name" onChange={handleChange} />
                  </div>
                </div>
                <div>
                  <label className="text-gray-800 text-sm mb-2 block">Password</label>
                  <div className="relative flex items-center">
                    <input name="password" type="password" required className="w-full text-sm text-gray-800 border border-gray-300 px-4 py-3 rounded-lg outline-blue-600" placeholder="Enter password" onChange={handleChange} />
                  </div>
                </div>

                <div className="!mt-8">
                  <button 
                    type="submit" 
                    disabled={isLoading}
                    className="w-full bg-blue-500 text-white py-2 rounded flex items-center justify-center"
                  >
                    {isLoading ? (
                      <>
                        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        Loading...
                      </>
                    ) : 'Login'}
                  </button>
                </div>
                <div className="text-sm">
                  <a href="/forgot-password" className="text-blue-600 hover:underline font-semibold">
                    Forgot your password?
                  </a>
                </div>

                <p className="text-sm !mt-8 text-center text-gray-800">Request Access? <a href="mailto:help@ukworkx.com" className="text-blue-600 font-semibold hover:underline ml-1 whitespace-nowrap">E-mail Us</a></p>
              </form>
            </div>
            <div className="lg:h-[400px] md:h-[300px] max-md:mt-8">
              <img src="https://readymadeui.com/login-image.webp" className="w-full h-full max-md:w-4/5 mx-auto block object-cover" alt="Dining Experience" />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Login