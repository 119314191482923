import { useState, useEffect, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { motion, AnimatePresence } from 'framer-motion';
import logo from '../../images/logo.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { useNotification } from "../../NotificationContext";
import InfoBanner from "../banners/InfoBanner"

const navigation = [
  { 
    name: 'Home', 
    href: '/',
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
      </svg>
    )
  },
  { 
    name: 'Sponsored Jobs Board', 
    href: '/jobs',
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
      </svg>
    )
  },
  { 
    name: 'Job Tracker', 
    href: '/applied-jobs',
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
      </svg>
    )
  },
  {
    name: "Manual Job Tailoring", 
    href: "/manual-job-tailoring",
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
      </svg>
    )
  }
];

export default function Navbar() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [scrolled, setScrolled] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { showNotification } = useNotification();

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }

    const handleScroll = () => {
      setScrolled(window.scrollY > 10);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const isActive = (href) => {
    return href === location.pathname;
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    setUser(null);
    showNotification('Logged out successfully', 'success');
    setMobileMenuOpen(false);
    navigate('/');
  };

  const getNavItemClasses = (isActive) => `
    relative text-sm font-semibold px-3 py-2 rounded-lg transition-all duration-200
    ${isActive 
      ? 'text-blue-600 bg-blue-50' 
      : 'text-gray-700 hover:text-blue-600 hover:bg-blue-50'}
    group flex items-center space-x-2 whitespace-nowrap
  `;

  const buttonClasses = "text-sm font-semibold px-4 py-2 rounded-lg bg-gradient-to-r from-blue-600 to-blue-600 text-white hover:from-blue-600 hover:to-blue-600 transition-all duration-200 shadow-md hover:shadow-lg ring-1 ring-blue-600";

  return (
    <>
      <InfoBanner />
      <motion.header 
        initial={{ y: -100 }}
        animate={{ y: 0 }}
        className={`fixed w-full z-40 transition-all duration-300 ${
          scrolled 
            ? 'bg-white shadow-lg backdrop-blur-lg' 
            : 'bg-white/80 backdrop-blur-sm'
        }`}
      >
        <nav 
          className="mx-auto flex max-w-7xl items-center justify-between p-4 lg:px-8" 
          aria-label="Global"
        >
          <motion.div 
            className="flex lg:flex-1"
            whileHover={{ scale: 1.02 }}
            transition={{ type: "spring", stiffness: 400, damping: 10 }}
          >
            <a href="/" className="group -m-1.5 p-1.5">
              <span className="sr-only">UK workx</span>
              <motion.img 
                className="h-10 sm:h-12 w-auto"
                src={logo} 
                alt="UK workx logo"
                whileHover={{ scale: 1.05 }}
                transition={{ type: "spring", stiffness: 400, damping: 10 }}
              />
            </a>
          </motion.div>
          
          <div className="flex lg:hidden">
            <motion.button
              whileTap={{ scale: 0.95 }}
              type="button"
              className="inline-flex items-center justify-center rounded-lg p-2.5 text-gray-700 hover:bg-gray-100 transition-all duration-200"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </motion.button>
          </div>

          <div className="hidden lg:flex lg:gap-x-6 items-center">
            {navigation.map((item) => (
              <motion.a
                key={item.name}
                href={item.href}
                className={getNavItemClasses(isActive(item.href))}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <span className="transition-transform duration-200 group-hover:scale-110">
                  {item.icon}
                </span>
                <span>{item.name}</span>
              </motion.a>
            ))}

            <div className="h-6 w-px bg-gray-200 mx-2" />

            {user ? (
              <div className="flex items-center gap-x-4">
                <motion.a 
                  href='/profile' 
                  className={getNavItemClasses(isActive('/profile'))}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                  </svg>
                  <span>{user.name}'s Profile</span>
                </motion.a>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={handleLogout}
                  className={buttonClasses}
                >
                  Logout
                </motion.button>
              </div>
            ) : (
              <motion.a
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                href="/login"
                className={buttonClasses}
              >
                Login
              </motion.a>
            )}
          </div>
        </nav>
        {/* Mobile Menu with improved animations */}
        <AnimatePresence>
  {mobileMenuOpen && (
    <Dialog 
      as={motion.div}
      className="lg:hidden relative z-40"
      onClose={() => setMobileMenuOpen(false)}
      open={mobileMenuOpen}  // Add this line
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
              <motion.div 
                className="fixed inset-0 bg-black/30 backdrop-blur-sm"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              />

              <Dialog.Panel
                as={motion.div}
                className="fixed inset-y-0 right-0 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm"
                initial={{ x: "100%" }}
                animate={{ x: 0 }}
                exit={{ x: "100%" }}
                transition={{ type: "spring", damping: 20 }}
              >
                <div className="flex items-center justify-between">
                  <motion.a 
                    href="/" 
                    className="-m-1.5 p-1.5"
                    whileHover={{ scale: 1.05 }}
                  >
                    <span className="sr-only">UK workx</span>
                    <img className="h-8 w-auto" src={logo} alt="UK workx logo" />
                  </motion.a>
                  <motion.button
                    whileTap={{ scale: 0.95 }}
                    type="button"
                    className="-m-2.5 rounded-lg p-2.5 text-gray-700 hover:bg-gray-100"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </motion.button>
                </div>

                <div className="mt-6 flow-root">
                  <div className="-my-6 divide-y divide-gray-500/10">
                    <div className="space-y-2 py-6">
                      {navigation.map((item) => (
                        <motion.a
                          key={item.name}
                          href={item.href}
                          className={`
                            group flex items-center gap-x-3 rounded-lg px-3 py-2 text-base font-semibold
                            ${isActive(item.href) 
                              ? 'bg-blue-50 text-blue-600' 
                              : 'text-gray-700 hover:bg-blue-50 hover:text-blue-600'}
                          `}
                          onClick={() => setMobileMenuOpen(false)}
                          whileHover={{ x: 4 }}
                          whileTap={{ scale: 0.98 }}
                        >
                          <span className="transition-transform duration-200 group-hover:scale-110">
                            {item.icon}
                          </span>
                          {item.name}
                        </motion.a>
                      ))}

{user ? (
  <>
    <motion.a
      href='/profile'
      className="group flex items-center gap-x-3 rounded-lg px-3 py-2 text-base font-semibold text-gray-700 hover:bg-blue-50 hover:text-blue-600"
      onClick={() => setMobileMenuOpen(false)}
      whileHover={{ x: 4 }}
      whileTap={{ scale: 0.98 }}
    >
      <span className="transition-transform duration-200 group-hover:scale-110">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
        </svg>
      </span>
      {user.name}'s Profile
    </motion.a>
    <motion.button
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
      onClick={handleLogout}
      className={`w-full mt-4 ${buttonClasses} flex items-center justify-center`}
    >
      Logout
    </motion.button>
  </>
) : (
  <div className="w-full">
    <motion.a
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
      href="/login"
      className={`w-full mt-4 ${buttonClasses} flex items-center justify-center`}
      onClick={() => setMobileMenuOpen(false)}
    >
      Login
    </motion.a>
  </div>
)}
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Dialog>
          )}
        </AnimatePresence>
      </motion.header>
      {/* Spacer to prevent content from going under the fixed navbar */}
    </>
  );
}