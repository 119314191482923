import React, { useState, useEffect } from 'react';
import { useNotification } from "../../NotificationContext";
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Navbar from '../navbar/Navbar';
import Footer from '../footer/Footer';
import api from '../../utils/api';

const columnsOrder = ['applied', 'shortlisted', 'interviewing', 'rejected'];
const columns = {
  applied: { 
    title: 'Applied', 
    color: 'bg-indigo-50', 
    key: 'appliedJobs',
    badge: 'bg-indigo-100 text-indigo-700',
    border: 'border-indigo-200/60',
  },
  shortlisted: { 
    title: 'Shortlisted', 
    color: 'bg-emerald-50', 
    key: 'shortlistedJobs',
    badge: 'bg-emerald-100 text-emerald-700',
    border: 'border-emerald-200/60',
  },
  interviewing: { 
    title: 'Interviewing', 
    color: 'bg-blue-50', 
    key: 'interviewingJobs',
    badge: 'bg-blue-100 text-blue-700',
    border: 'border-blue-200/60',
  },
  rejected: { 
    title: 'Rejected', 
    color: 'bg-slate-50', 
    key: 'rejectedJobs',
    badge: 'bg-slate-100 text-slate-700',
    border: 'border-slate-200/60',
  },
};

// Add Modal Component
const AddJobModal = ({ isOpen, onClose, onSubmit }) => {
  const [formData, setFormData] = useState({
    job_title: '',
    employer_name: '',
    job_description: '',
    job_apply_link: '',
    application_date: new Date().toISOString().split('T')[0]
  });

  if (!isOpen) return null;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
    setFormData({
      job_title: '',
      employer_name: '',
      job_description: '',
      job_apply_link: '',
      application_date: new Date().toISOString().split('T')[0]
    });
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-xl shadow-xl w-full max-w-md">
        <div className="p-6">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-xl font-semibold text-slate-900">Add Manually</h2>
            <button
              onClick={onClose}
              className="text-slate-400 hover:text-slate-500"
              aria-label="Close modal"
            >
              <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>

          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label htmlFor="job_title" className="block text-sm font-medium text-slate-700 mb-1">
                Job Title *
              </label>
              <input
                id="job_title"
                name="job_title"
                type="text"
                required
                value={formData.job_title}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-slate-300 rounded-lg focus:outline-none 
                  focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                placeholder="e.g. Software Engineer"
              />
            </div>

            <div>
              <label htmlFor="employer_name" className="block text-sm font-medium text-slate-700 mb-1">
                Company Name *
              </label>
              <input
                id="employer_name"
                name="employer_name"
                type="text"
                required
                value={formData.employer_name}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-slate-300 rounded-lg focus:outline-none 
                  focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                placeholder="e.g. Tech Corp"
              />
            </div>

            <div>
              <label htmlFor="job_description" className="block text-sm font-medium text-slate-700 mb-1">
                Job Description
              </label>
              <textarea
                id="job_description"
                name="job_description"
                value={formData.job_description}
                onChange={handleChange}
                rows="3"
                className="w-full px-3 py-2 border border-slate-300 rounded-lg focus:outline-none 
                  focus:ring-2 focus:ring-blue-500 focus:border-blue-500 resize-none"
                placeholder="Enter job description (optional)"
              />
            </div>

            <div>
              <label htmlFor="job_apply_link" className="block text-sm font-medium text-slate-700 mb-1">
                Job Link
              </label>
              <input
                id="job_apply_link"
                name="job_apply_link"
                type="url"
                value={formData.job_apply_link}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-slate-300 rounded-lg focus:outline-none 
                  focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                placeholder="e.g. https://company.com/jobs/123"
              />
            </div>

            <div>
              <label htmlFor="application_date" className="block text-sm font-medium text-slate-700 mb-1">
                Application Date *
              </label>
              <input
                id="application_date"
                name="application_date"
                type="date"
                required
                value={formData.application_date}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-slate-300 rounded-lg focus:outline-none 
                  focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>

            <div className="flex justify-end space-x-3 pt-4">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 text-sm font-medium text-slate-700 bg-white border border-slate-300 
                  rounded-lg hover:bg-slate-50 focus:outline-none focus:ring-2 focus:ring-blue-500 
                  focus:ring-offset-2 transition-colors duration-200"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-lg 
                  hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 
                  focus:ring-offset-2 transition-colors duration-200"
              >
                Add Job
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

// Job Card Component
const JobCard = ({ job, columnId, onMove, onRemove }) => {
  const [openMenu, setOpenMenu] = useState(false);

  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'JOB_CARD',
    item: { jobId: job.job_id, fromColumn: columnId },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  const formatDate = (dateString) => {
    if (!dateString) return 'Date not available';
    return new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    }).format(new Date(dateString));
  };

  const handleClickOutside = (e) => {
    if (openMenu && !e.target.closest('.menu-container')) {
      setOpenMenu(false);
    }
  };

  useEffect(() => {
    if (openMenu) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [openMenu]);

  return (
    <div
      ref={drag}
      className={`group bg-white rounded-xl border border-slate-200/70 shadow-sm hover:shadow-md
        transition-all duration-200 ${isDragging ? 'opacity-50' : ''}`}
    >
      <div className="p-5">
        {/* Action Buttons */}
        <div className="flex justify-end mb-3 md:opacity-0 md:group-hover:opacity-100 transition-opacity">
          <div className="flex items-center space-x-1">
            {/* Delete Button */}
            <button
              onClick={(e) => {
                e.stopPropagation();
                onRemove(job.job_id, columnId);
              }}
              className="p-1.5 text-slate-400 hover:text-red-500 hover:bg-red-50 rounded-md 
                transition-colors duration-200"
              aria-label="Delete job"
            >
              <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" 
                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
              </svg>
            </button>
            
            {/* Move Menu */}
            <div className="relative menu-container">
              <button
                onClick={() => setOpenMenu(!openMenu)}
                className="p-1.5 text-slate-400 hover:text-slate-600 hover:bg-slate-50 rounded-md
                  transition-colors duration-200"
                aria-label="More options"
              >
                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
                </svg>
              </button>

              {openMenu && (
                <div className="absolute right-0 mt-2 w-56 rounded-lg shadow-lg bg-white border border-slate-200 z-10">
                  <div className="py-1" role="menu">
                    {columnsOrder.filter(colId => colId !== columnId).map(colId => (
                      <button
                        key={colId}
                        onClick={(e) => {
                          e.stopPropagation();
                          onMove(job.job_id, columnId, colId);
                          setOpenMenu(false);
                        }}
                        className="w-full px-4 py-2.5 text-left text-sm text-slate-700 hover:bg-slate-50 
                          flex items-center transition-colors duration-200"
                      >
                        <div className={`w-2 h-2 rounded-full ${columns[colId].badge.split(' ')[0]} mr-2`} />
                        Move to {columns[colId].title}
                      </button>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Job Information */}
        <div className="space-y-3">
          <div>
            <h3 className="font-semibold text-slate-900 mb-1 leading-tight">
              {job.job_title}
            </h3>
            <p className="text-sm text-slate-600 font-medium">
              {job.employer_name}
            </p>
          </div>

          {job.job_description && (
            <p className="text-sm text-slate-600 line-clamp-2">
              {job.job_description}
            </p>
          )}
          
          {/* Status and Date */}
          <div className="flex items-center gap-3 text-sm">
            <span className={`inline-flex px-2.5 py-1 rounded-md text-xs font-medium ${columns[columnId].badge}`}>
              {columns[columnId].title}
            </span>
            <span className="text-slate-500">
              {formatDate(job[`${columnId}_at`])}
            </span>
          </div>
          
          {/* View Details Link */}
          {job.job_apply_link && (
            <div className="pt-2">
              <a
                href={job.job_apply_link}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center px-3.5 py-2 text-sm font-medium text-slate-700 
                  bg-slate-100 hover:bg-slate-200 rounded-lg transition-colors duration-200"
              >
                View Details
                <svg className="ml-2 w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" 
                    d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                </svg>
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

// Delete Zone Component
const DeleteZone = ({ onRemove }) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'JOB_CARD',
    drop: (item) => {
      onRemove(item.jobId, item.fromColumn);
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  return (
    <div
      ref={drop}
      className={`fixed bottom-0 left-0 w-full h-28 backdrop-blur-sm transition-all duration-300
        ${isOver ? 'bg-red-500/90' : 'bg-red-500/80'}`}
    >
      <div className="h-full flex items-center justify-center">
        <div className={`text-white text-center transform transition-transform duration-200 
          ${isOver ? 'scale-105' : 'scale-100'}`}>
          <svg className="w-8 h-8 mx-auto mb-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" 
              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
          </svg>
          <p className="text-lg font-medium">Drop to Remove</p>
        </div>
      </div>
    </div>
  );
};

// Column Component
const Column = ({ columnId, jobs, onMove, onRemove, visibleJobs, onLoadMore }) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'JOB_CARD',
    drop: (item) => {
      if (item.fromColumn !== columnId) {
        onMove(item.jobId, item.fromColumn, columnId);
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  return (
    <div
      ref={drop}
      className={`h-full ${columns[columnId].color} rounded-xl border ${columns[columnId].border}
        ${isOver ? 'ring-2 ring-blue-400/50' : ''} transition-all duration-200`}
    >
      <div className="p-5">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-lg font-semibold text-slate-900">
            {columns[columnId].title}
          </h2>
          <span className={`${columns[columnId].badge} px-2.5 py-1 rounded-md text-xs font-medium`}>
            {jobs.length}
          </span>
        </div>

        <div className="space-y-4">
          {jobs.slice(0, visibleJobs[columns[columnId].key]).map((job) => (
            <JobCard
              key={job.job_id}
              job={job}
              columnId={columnId}
              onMove={onMove}
              onRemove={onRemove}
            />
          ))}

          {jobs.length > visibleJobs[columns[columnId].key] && (
            <button
              onClick={() => onLoadMore(columns[columnId].key)}
              className="w-full py-2.5 px-4 text-sm font-medium text-slate-600 bg-white rounded-lg 
                border border-slate-200 hover:bg-slate-50 transition-colors duration-200"
            >
              Show More ({jobs.length - visibleJobs[columns[columnId].key]})
            </button>
          )}

          {jobs.length === 0 && (
            <div className="text-center py-8">
              <p className="text-sm text-slate-500">No jobs yet</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

function JobTracker() {
  const [jobState, setJobState] = useState({
    appliedJobs: [],
    shortlistedJobs: [],
    interviewingJobs: [],
    rejectedJobs: [],
  });
  const [isLoading, setIsLoading] = useState(true);
  const { showNotification } = useNotification();
  const [visibleJobs, setVisibleJobs] = useState({
    appliedJobs: 10,
    shortlistedJobs: 10,
    interviewingJobs: 10,
    rejectedJobs: 10,
  });
  const [isDragging, setIsDragging] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  useEffect(() => {
    fetchAllJobStages();
    
    const handleDragStart = () => setIsDragging(true);
    const handleDragEnd = () => setIsDragging(false);

    window.addEventListener('dragstart', handleDragStart);
    window.addEventListener('dragend', handleDragEnd);

    return () => {
      window.removeEventListener('dragstart', handleDragStart);
      window.removeEventListener('dragend', handleDragEnd);
    };
  }, []);

  const fetchAllJobStages = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        showNotification('Please log in to view jobs');
        return;
      }

      const response = await api.get("/user/job-stages", {
        headers: { Authorization: `Bearer ${token}` }
      });
      
      const sortedJobs = Object.keys(response.data).reduce((acc, key) => {
        acc[key] = response.data[key].sort((a, b) => {
          const dateA = new Date(a[`${key.slice(0, -4)}_at`]);
          const dateB = new Date(b[`${key.slice(0, -4)}_at`]);
          return dateB - dateA;
        });
        return acc;
      }, {});

      setJobState(sortedJobs);
    } catch (error) {
      console.error("Error fetching job stages:", error);
      showNotification('Error fetching job stages');
    } finally {
      setIsLoading(false);
    }
  };

  const moveJob = async (jobId, fromColumn, toColumn) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        showNotification('Please log in to move jobs');
        return;
      }

      await api.post("/jobs/move", {
        jobId,
        fromStage: columns[fromColumn].key,
        toStage: columns[toColumn].key
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });

      setJobState(prevState => {
        const fromKey = columns[fromColumn].key;
        const toKey = columns[toColumn].key;
        const job = prevState[fromKey].find(j => j.job_id === jobId);
        if (!job) return prevState;
        
        const updatedToJobs = [...prevState[toKey], { ...job, [`${toColumn}_at`]: new Date() }]
          .sort((a, b) => new Date(b[`${toColumn}_at`]) - new Date(a[`${toColumn}_at`]));

        return {
          ...prevState,
          [fromKey]: prevState[fromKey].filter(j => j.job_id !== jobId),
          [toKey]: updatedToJobs,
        };
      });

      showNotification('Job moved successfully', 'success');
    } catch (error) {
      console.error("Error moving job:", error);
      showNotification('Error moving job');
    }
  };

  const removeJob = async (jobId, fromColumn) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        showNotification('Please log in to remove jobs');
        return;
      }
  
      await api.post("/jobs/remove", {
        jobId,
        stage: columns[fromColumn].key
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });
  
      setJobState(prevState => ({
        ...prevState,
        [columns[fromColumn].key]: prevState[columns[fromColumn].key].filter(job => job.job_id !== jobId)
      }));
  
      showNotification('Job removed successfully', 'success');
    } catch (error) {
      console.error("Error removing job:", error);
      showNotification('Error removing job');
    }
  };

  const handleAddJob = async (jobData) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        showNotification('Please log in to add jobs');
        return;
      }

      const response = await api.post("/jobs/manual-add", {
        ...jobData
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });

      setJobState(prevState => ({
        ...prevState,
        appliedJobs: [
          {
            ...response.data,
            applied_at: jobData.application_date,
            job_id: response.data.id || response.data.job_id
          },
          ...prevState.appliedJobs
        ]
      }));

      setIsAddModalOpen(false);
      showNotification('Job added successfully', 'success');
    } catch (error) {
      console.error("Error adding job:", error);
      showNotification('Error adding job');
    }
  };

  const loadMore = (columnKey) => {
    setVisibleJobs(prev => ({
      ...prev,
      [columnKey]: prev[columnKey] + 10
    }));
  };

  return (
    <div className="min-h-screen bg-slate-50">
      <div className="pb-12">
        <Navbar />
      </div>
      
      <main className="container mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="text-center max-w-3xl mx-auto mb-12">
          <h1 className="text-3xl font-bold text-slate-900 sm:text-4xl mb-4 pt-6">
            Job Applications Dashboard
          </h1>
          <p className="text-lg text-slate-600 mb-8">
            Track and manage your job applications efficiently
          </p>
          
          <button
            onClick={() => setIsAddModalOpen(true)}
            className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-lg 
              hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 
              focus:ring-offset-2 transition-colors duration-200"
          >
            Add Manually
          </button>
        </div>

        <AddJobModal
          isOpen={isAddModalOpen}
          onClose={() => setIsAddModalOpen(false)}
          onSubmit={handleAddJob}
        />

        {isLoading ? (
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-12 w-12 border-4 border-slate-200 border-t-blue-600" />
          </div>
        ) : (
          <DndProvider backend={HTML5Backend}>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
              {columnsOrder.map((columnId) => (
                <Column
                  key={columnId}
                  columnId={columnId}
                  jobs={jobState[columns[columnId].key]}
                  onMove={moveJob}
                  onRemove={removeJob}
                  visibleJobs={visibleJobs}
                  onLoadMore={loadMore}
                />
              ))}
            </div>
            {isDragging && (
              <div className="hidden md:block">
                <DeleteZone onRemove={removeJob} />
              </div>
            )}
          </DndProvider>
        )}
      </main>
      <Footer />
    </div>
  );
}

export default JobTracker;