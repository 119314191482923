import React from 'react'
import Footer from "../features/footer/Footer"

import JobDashboard from "../features/jobs/JobDashboard"

function JobDashboardPage() {
  return (
    <>
    
    <JobDashboard />
    <Footer/>
    </>
  )
}

export default JobDashboardPage