import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

export default function InfoBanner() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const hasSeenUpdate = localStorage.getItem('hasSeenVersion120');
    if (!hasSeenUpdate) {
      setIsVisible(true);
      localStorage.setItem('hasSeenVersion120', 'true');
    }
  }, []);

  const handleClose = () => {
    setIsVisible(false);
  };

  return (
    <AnimatePresence>
      {isVisible && (
        <div className="fixed inset-0 flex items-center justify-center z-50 p-4">
          {/* Backdrop */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="absolute inset-0 bg-black/50 backdrop-blur-sm"
            onClick={handleClose}
          />

          {/* Modal */}
          <motion.div
            initial={{ opacity: 0, scale: 0.95, y: 20 }}
            animate={{ opacity: 1, scale: 1, y: 0 }}
            exit={{ opacity: 0, scale: 0.95, y: 20 }}
            transition={{ type: "spring", duration: 0.5, bounce: 0.3 }}
            className="relative w-full max-w-3xl max-h-[90vh] overflow-hidden bg-white rounded-2xl shadow-2xl"
          >
            {/* Header */}
            <div className="bg-gradient-to-r from-blue-600 to-blue-600 px-4 sm:px-6 py-4">
              <div className="flex items-center justify-between">
                <h2 className="text-lg sm:text-xl font-bold text-white pr-8">
                  Ukworkx Job Board Update: Version 1.2.0 Release
                </h2>
                <button
                  onClick={handleClose}
                  className="absolute right-4 top-4 rounded-full p-1 hover:bg-white/10 transition-colors"
                  aria-label="Close dialog"
                >
                  <X className="w-5 h-5 text-white" />
                </button>
              </div>
            </div>

            {/* Content */}
            <div className="overflow-y-auto px-4 sm:px-6 py-6" style={{ maxHeight: 'calc(90vh - 160px)' }}>
              <p className="text-gray-700 mb-6">
                We are excited to announce the latest update to the Ukworkx Job Board, bringing new features and enhancements to improve your job search experience. Here's what's new in version 1.2.0:
              </p>

              <div className="space-y-6">
                <Feature
                  title="Job Match Score"
                  description="Each job listing now includes a match score, helping you assess the likelihood of your fit for the role. The higher the score, the higher the chances of you securing it."
                />

                <Feature
                  title="Confirmation for Job Tracking"
                  description="Jobs will no longer be automatically added to your job tracker when clicked. You will now receive a prompt to confirm before adding a job to your tracker."
                />

                <Feature
                  title="CV and Cover Letter Generation for any role"
                  description="Our new manual job tailoring feature allows you to generate personalized CVs and cover letters even if the jobs are not available on our platform. Simply copy and paste the job description, and we'll handle the rest."
                />

                <Feature
                  title="Enhanced Password Management"
                  description="Reset your password as often as needed with our updated password reset feature, ensuring personalized and secure account access."
                />

                <Feature
                  title="Comprehensive Application Tracking"
                  description="Track all your applications in one place, including jobs applied outside our platform, by manually adding them to your job tracker."
                />

                <Feature
                  title="Drag-and-Drop Functionality (Desktop Only)"
                  description="Enjoy improved usability with the ability to drag and drop jobs between different sections of the job tracker."
                />
              </div>

              <p className="mt-6 text-gray-700 italic">
                We're committed to continuously improving your experience. Try out these features today and let us know your feedback!
              </p>
            </div>

            {/* Footer */}
            <div className="border-t border-gray-200 px-4 sm:px-6 py-4 bg-gray-50">
              <button
                onClick={handleClose}
                className="w-full px-4 py-3 text-sm font-semibold text-white rounded-lg bg-gradient-to-r from-blue-600 to-blue-600 hover:from-blue-600 hover:to-blue-650 transition-all duration-200 shadow-md hover:shadow-lg focus:ring-2 focus:ring-blue-600 focus:ring-offset-2"
              >
                Got it, thanks!
              </button>
            </div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
}

// Feature component for better organization
function Feature({ title, description }) {
  return (
    <div className="flex gap-3">
      <div className="flex-shrink-0 w-1.5 bg-blue-600 rounded-full mt-2"></div>
      <div>
        <h3 className="font-semibold text-gray-900">{title}</h3>
        <p className="text-gray-600 mt-1">{description}</p>
      </div>
    </div>
  );
}