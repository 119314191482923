import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import api from '../../utils/api';
import { useNotification } from "../../NotificationContext";

function AdminProtectedRoute({ children }) {
  const [isLoading, setIsLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();
  const { showNotification } = useNotification();

  useEffect(() => {
    const checkAdminStatus = async () => {
      const token = localStorage.getItem('adminToken');
      if (!token) {
        setIsLoading(false);
        return;
      }

      try {
        const response = await api.get('/admin/verify', {
          headers: { Authorization: `Bearer ${token}` }
        });
        setIsAdmin(true);
      } catch (error) {
        console.error('Error verifying admin status:', error);
        localStorage.removeItem('adminToken');
        showNotification('Admin session expired. Please log in again.', 'error');
      } finally {
        setIsLoading(false);
      }
    };

    checkAdminStatus();
  }, [showNotification]);

  if (isLoading) {
    return <div>Loading...</div>; // Or a loading spinner
  }

  if (!isAdmin) {
    return <Navigate to="/admin/login" replace />;
  }

  return children;
}

export default AdminProtectedRoute;