import React, { useState, useEffect } from 'react';
import { useNotification } from "../../NotificationContext";
import { motion } from 'framer-motion';
import { Mail, Timer, ArrowRight, Check } from 'lucide-react';
import Navbar from '../navbar/Navbar';
import Footer from '../footer/Footer';
import api from '../../utils/api';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [countdown, setCountdown] = useState(120); // 2 minutes in seconds
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const { showNotification } = useNotification();

  useEffect(() => {
    let timer;
    if (isEmailSent && countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);
    } else if (countdown === 0) {
      setIsEmailSent(false);
      setIsButtonDisabled(false);
      setCountdown(120);
    }
    return () => clearInterval(timer);
  }, [isEmailSent, countdown]);

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs.toString().padStart(2, '0')}`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await api.post('/auth/forgot-password', { email });
      setIsEmailSent(true);
      setIsButtonDisabled(true);
      showNotification('Password reset email sent successfully!', 'success');
    } catch (error) {
      const errorMessage = error.response?.data?.error || 'An error occurred. Please try again.';
      showNotification(errorMessage, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  return (
    <div className="min-h-screen flex flex-col">
      <Navbar />
      
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="flex-grow flex items-center justify-center px-4 py-12"
      >
        <div className="max-w-md w-full space-y-8 bg-white p-8 rounded-xl shadow-lg">
          <div className="text-center">
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ delay: 0.2 }}
              className="mx-auto h-12 w-12 bg-blue-100 rounded-full flex items-center justify-center"
            >
              <Mail className="h-6 w-6 text-blue-600" />
            </motion.div>
            <motion.h2
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.3 }}
              className="mt-6 text-3xl font-extrabold text-gray-900"
            >
              Forgot Password
            </motion.h2>
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.4 }}
              className="mt-2 text-sm text-gray-600"
            >
              Enter your email address and we'll send you a link to reset your password.
            </motion.p>
          </div>

          <motion.form
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5 }}
            onSubmit={handleSubmit}
            className="mt-8 space-y-6"
          >
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <label htmlFor="email" className="sr-only">Email address</label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="appearance-none rounded-lg relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                  placeholder="Email address"
                  disabled={isEmailSent}
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                disabled={isButtonDisabled || isLoading || !isValidEmail(email)}
                className={`group relative w-full flex justify-center py-3 px-4 border border-transparent text-sm font-medium rounded-lg text-white 
                  ${isButtonDisabled || !isValidEmail(email)
                    ? 'bg-gray-400 cursor-not-allowed'
                    : 'bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                  } transition-all duration-200 ease-in-out`}
              >
                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  {isLoading ? (
                    <motion.div
                      animate={{ rotate: 360 }}
                      transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
                    >
                      <Timer className="h-5 w-5 text-blue-300" />
                    </motion.div>
                  ) : isEmailSent ? (
                    <Check className="h-5 w-5 text-green-300" />
                  ) : (
                    <ArrowRight className="h-5 w-5 text-blue-300" />
                  )}
                </span>
                {isLoading ? 'Sending...' : isEmailSent ? 'Email Sent!' : 'Send Reset Link'}
              </button>
            </div>

            {isEmailSent && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                className="text-center"
              >
                <p className="text-sm text-gray-600">Next attempt available in:</p>
                <p className="text-lg font-semibold text-blue-600">{formatTime(countdown)}</p>
              </motion.div>
            )}
          </motion.form>
        </div>
      </motion.div>
      
      <Footer />
    </div>
  );
};

export default ForgotPassword;