import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Navbar from '../features/navbar/Navbar';
import Footer from '../features/footer/Footer';
import { useNotification } from "../NotificationContext";
import api from '../utils/api';

const JobDetailsPage = () => {
  const [job, setJob] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [applicationData, setApplicationData] = useState(null);
  const [isGeneratingDocuments, setIsGeneratingDocuments] = useState(false);
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [isRegeneratingResume, setIsRegeneratingResume] = useState(false);
  const [isRegeneratingCoverLetter, setIsRegeneratingCoverLetter] = useState(false);
  const [isDownloadingCoverLetter, setIsDownloadingCoverLetter] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [showJobMatchDialog, setShowJobMatchDialog] = useState(false);
  const [jobMatchScore, setJobMatchScore] = useState(null);
  const [jobMatchReasons, setJobMatchReasons] = useState([]);
  const [showMatchDetails, setShowMatchDetails] = useState(false);
  const { jobId } = useParams();
  const navigate = useNavigate();
  const { showNotification } = useNotification();
  const [coverLetterContent, setCoverLetterContent] = useState('');
  const [hasResume, setHasResume] = useState(false);

  const careerMessages = [
    "Crafting your professional story...",
    "Highlighting your unique skills...",
    "Tailoring your experience to the role...",
    "Polishing your career achievements...",
    "Preparing to showcase your potential..."
  ];

  const analyzeMessages = [
    "Analyzing job requirements...",
    "Comparing skills and experience...",
    "Evaluating qualifications...",
    "Calculating match score...",
    "Preparing detailed analysis..."
  ];

  const fetchJobDetails = useCallback(async () => {
    try {
      const response = await api.get(`/jobs/${jobId}`);
      setJob(response.data);
    } catch (error) {
      setError('Failed to load job details. Please try again later.');
      showNotification('Failed to load job details. Please try again later.', 'error');
    } finally {
      setLoading(false);
    }
  }, [jobId, showNotification]);

  useEffect(() => {
    fetchJobDetails();
  }, [fetchJobDetails]);

  const startAnalysisProgress = () => {
    setLoadingProgress(0);
    const updateProgress = () => {
      setLoadingProgress(prev => {
        const newProgress = prev + Math.random() * 20;
        return newProgress > 90 ? 90 : newProgress;
      });
      setLoadingMessage(analyzeMessages[Math.floor(Math.random() * analyzeMessages.length)]);
    };
    return setInterval(updateProgress, 1000);
  };

  const analyzeJobMatch = async () => {
    setIsAnalyzing(true);
    const progressInterval = startAnalysisProgress();

    try {
      const response = await api.post('/manual/analyze-match', { 
        jobDescription: job.job_description 
      });
      setLoadingProgress(100);
      await new Promise(resolve => setTimeout(resolve, 500)); // Smooth transition
      setJobMatchScore(response.data.score);
      setJobMatchReasons(response.data.reasons);
      setShowJobMatchDialog(true);
    } catch (error) {
      console.error('Error analyzing job match:', error);
      showNotification('Failed to analyze job match. Please try again.', 'error');
    } finally {
      clearInterval(progressInterval);
      setIsAnalyzing(false);
      setLoadingProgress(0);
    }
  };

  const checkUserResume = useCallback(async () => {
    try {
      const response = await api.get(`/user/has-resume`);
      setHasResume(response.data.hasResume);
    } catch (error) {
      console.error('Error checking user resume:', error);
      setHasResume(false);
    }
  }, []);
  
  useEffect(() => {
    fetchJobDetails();
    checkUserResume();
  }, [fetchJobDetails, checkUserResume]);

  const generateDocuments = async () => {
    try {
      await analyzeJobMatch();
    } catch (error) {
      console.error('Error in initial job match analysis:', error);
    }
  };

  const proceedWithGeneration = async () => {
    setShowJobMatchDialog(false);
    setIsGeneratingDocuments(true);
    setError(null);
    setLoadingProgress(0);
    
    const updateProgress = () => {
      setLoadingProgress(prev => {
        const newProgress = prev + Math.random() * 15;
        return newProgress > 90 ? 90 : newProgress;
      });
      setLoadingMessage(careerMessages[Math.floor(Math.random() * careerMessages.length)]);
    };

    const progressInterval = setInterval(updateProgress, 2500);

    try {
      const token = localStorage.getItem('token');
      const resumeResponse = await api.post(`/jobs/${jobId}/apply`, {}, {
        headers: { Authorization: `Bearer ${token}` }
      });
      
      const coverLetterResponse = await api.post(`/jobs/${jobId}/generate-cover-letter`, {}, {
        headers: { Authorization: `Bearer ${token}` }
      });

      setApplicationData(resumeResponse.data);
      setCoverLetterContent(coverLetterResponse.data.content);
      showNotification('Documents generated successfully!', 'success');
    } catch (error) {
      const errorMessage = error.response?.data?.error || 'An error occurred while generating documents';
      setError(errorMessage);
      showNotification(errorMessage, 'error');
    } finally {
      clearInterval(progressInterval);
      setLoadingProgress(100);
      setIsGeneratingDocuments(false);
    }
  };

  const regenerateDocuments = async () => {
    setIsGeneratingDocuments(true);
    setError(null);
    setLoadingProgress(0);
    
    const updateProgress = () => {
      setLoadingProgress(prev => {
        const newProgress = prev + Math.random() * 15;
        return newProgress > 90 ? 90 : newProgress;
      });
      setLoadingMessage(careerMessages[Math.floor(Math.random() * careerMessages.length)]);
    };
  
    const progressInterval = setInterval(updateProgress, 2500);
  
    try {
      const token = localStorage.getItem('token');
      const resumeResponse = await api.post(`/jobs/${jobId}/apply`, {}, {
        headers: { Authorization: `Bearer ${token}` }
      });
      
      const coverLetterResponse = await api.post(`/jobs/${jobId}/generate-cover-letter`, {}, {
        headers: { Authorization: `Bearer ${token}` }
      });
  
      setApplicationData(resumeResponse.data);
      setCoverLetterContent(coverLetterResponse.data.content);
      showNotification('Documents regenerated successfully!', 'success');
    } catch (error) {
      const errorMessage = error.response?.data?.error || 'An error occurred while regenerating documents';
      setError(errorMessage);
      showNotification(errorMessage, 'error');
    } finally {
      clearInterval(progressInterval);
      setLoadingProgress(100);
      setIsGeneratingDocuments(false);
    }
  };

  const regenerateResume = async () => {
    setIsRegeneratingResume(true);
    try {
      const token = localStorage.getItem('token');
      const response = await api.post(`/jobs/${jobId}/apply`, {}, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setApplicationData(response.data);
      showNotification('Resume regenerated successfully!', 'success');
    } catch (error) {
      showNotification('Failed to regenerate resume. Please try again.', 'error');
    } finally {
      setIsRegeneratingResume(false);
    }
  };

  const regenerateCoverLetter = async () => {
    setIsRegeneratingCoverLetter(true);
    try {
      const token = localStorage.getItem('token');
      const response = await api.post(`/jobs/${jobId}/generate-cover-letter`, {}, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setCoverLetterContent(response.data.content);
      showNotification('Cover letter regenerated successfully!', 'success');
    } catch (error) {
      showNotification('Failed to regenerate cover letter. Please try again.', 'error');
    } finally {
      setIsRegeneratingCoverLetter(false);
    }
  };

  const downloadCoverLetter = async () => {
    setIsDownloadingCoverLetter(true);
    try {
      const token = localStorage.getItem('token');
      const response = await api.get(`/jobs/${jobId}/cover-letter`, {
        responseType: 'arraybuffer',
        headers: { 
          Authorization: `Bearer ${token}`,
          'Accept': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        }
      });
      
      const blob = new Blob([response.data], { 
        type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' 
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `cover_letter_${jobId}.docx`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);

      showNotification('Cover letter downloaded successfully!', 'success');
    } catch (error) {
      showNotification('Failed to download cover letter. Please try again.', 'error');
    } finally {
      setIsDownloadingCoverLetter(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        <p className="text-red-500 text-xl mb-4">{error}</p>
        <button 
          onClick={() => navigate('/jobs')}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
        >
          Back to Jobs
        </button>
      </div>
    );
  }

  if (!job) {
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        <p className="text-xl mb-4">Job not found.</p>
        <button 
          onClick={() => navigate('/jobs')}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
        >
          Back to Jobs
        </button>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-col">
      <div className='pb-20'>
        <Navbar />
      </div>
      <main className="flex-grow container mx-auto pb-4 pt-10 py-8">
        <div className="bg-white rounded-lg overflow-hidden animate-fade-in">
          <div className="p-6">
            <h1 className="text-3xl font-bold mb-4">{job.job_title}</h1>
            <div className="flex flex-wrap justify-between items-start">
              <div>
                <p className="text-xl font-bold">{job.employer_name}</p>
                <p className="text-gray-600">Location: {job.job_city || 'N/A'}</p>
                <p className="mt-2">{job.job_employment_type}</p>
                <p className="mt-2">Posted: {new Date(job.job_posted_at_datetime_utc).toLocaleDateString()}</p>
              </div>
              {job.employer_logo && (
                <img 
                  src={job.employer_logo} 
                  alt={job.employer_name} 
                  className="w-32 h-32 object-contain transition-transform duration-300 hover:scale-105" 
                />
              )}
            </div>
          </div>

          <div className="border-t border-gray-200 p-6">
            <h2 className="text-2xl font-semibold mb-4">Job Description</h2>
            <p className="whitespace-pre-wrap">{job.job_description}</p>
          </div>

          {job.job_highlights && Object.keys(job.job_highlights).length > 0 && (
            <div className="border-t border-gray-200 p-6">
              {Object.entries(job.job_highlights).map(([key, values]) => (
                <div key={key} className="mb-6">
                  <h2 className="text-2xl font-semibold mb-2">{key}</h2>
                  <ul className="list-disc pl-5">
                    {values.map((item, index) => (
                      <li key={index} className="mb-1">{item}</li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          )}

          {job.apply_options && job.apply_options.length > 0 && (
            <div className="border-t border-gray-200 p-6">
              <h2 className="text-2xl font-semibold mb-4">Apply Links</h2>
              <ul className="list-disc pl-5">
                {job.apply_options.map((option, index) => (
                  <li key={index} className="mb-2">
                    <a 
                      href={option.apply_link} 
                      target="_blank" 
                      rel="noopener noreferrer"
                      className="text-blue-600 hover:text-blue-800 underline"
                    >
                      Apply on {option.publisher}
                    </a>
                    {option.is_direct && " (Direct application)"}
                  </li>
                ))}
              </ul>
            </div>
          )}
          
          {job.job_highlights && Object.keys(job.job_highlights).length > 0 && (
            <div className="border-t border-gray-200 p-6">
              {Object.entries(job.job_highlights).map(([key, values]) => (
                <div key={key} className="mb-6">
                  <h2 className="text-2xl font-semibold mb-2">{key}</h2>
                  <ul className="list-disc pl-5 space-y-2">
                    {values.map((item, index) => (
                      <li 
                        key={index} 
                        className="mb-1 animate-slide-in"
                        style={{ animationDelay: `${index * 100}ms` }}
                      >
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          )}

          {/* Job Match Dialog */}
          {showJobMatchDialog && (
            <div className="fixed inset-0 z-50">
              <div 
                className="fixed inset-0 bg-black bg-opacity-50 transition-opacity animate-fade-in backdrop-blur-sm" 
                onClick={() => setShowJobMatchDialog(false)}
              ></div>
              <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4">
                  <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:w-full sm:max-w-lg animate-slide-up">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                      <div className="flex flex-col items-center">
                        <h3 className="text-2xl font-semibold mb-6">Job Match Analysis</h3>
                        
                        <div className="relative w-48 h-48 mb-6">
                          {/* Circular Progress Bar */}
                          <svg className="transform -rotate-90 w-48 h-48">
                            <circle
                              cx="96"
                              cy="96"
                              r="88"
                              className="stroke-current text-gray-200"
                              strokeWidth="12"
                              fill="transparent"
                            />
                            <circle
                              cx="96"
                              cy="96"
                              r="88"
                              className={`stroke-current ${jobMatchScore >= 70 ? 'text-green-500' : 'text-yellow-500'} transition-all duration-1000 ease-out`}
                              strokeWidth="12"
                              fill="transparent"
                              strokeDasharray={553}
                              strokeDashoffset={553 - (553 * jobMatchScore) / 100}
                              style={{ transition: 'stroke-dashoffset 1s ease-out' }}
                            />
                          </svg>
                          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
                            <span className={`text-4xl font-bold ${jobMatchScore >= 70 ? 'text-green-600' : 'text-yellow-600'} animate-number`}>
                              {jobMatchScore}%
                            </span>
                          </div>
                        </div>

                        <button
                          onClick={() => setShowMatchDetails(!showMatchDetails)}
                          className="mb-4 bg-yellow-500 hover:bg-yellow-600 text-white px-4 py-2 rounded-full transition-all duration-200 flex items-center space-x-2 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-yellow-400 focus:ring-opacity-50"
                        >
                          <span className="text-lg font-bold">!</span>
                          <span className="text-sm">View Match Details</span>
                        </button>

                        {showMatchDetails && (
                          <div className="w-full bg-gray-50 p-4 rounded-lg animate-fade-in">
                            <h4 className="font-semibold mb-3 text-lg">
                              {jobMatchScore >= 70 ? 'Why this job is a good match:' : 'Areas of consideration:'}
                            </h4>
                            <ul className="list-disc pl-5 space-y-2">
                              {jobMatchReasons.map((reason, index) => (
                                <li 
                                  key={index} 
                                  className="text-sm text-gray-700 animate-slide-in"
                                  style={{ animationDelay: `${index * 100}ms` }}
                                >
                                  {reason}
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                      <button
                        type="button"
                        onClick={proceedWithGeneration}
                        className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 transition-all duration-200 sm:ml-3 sm:w-auto hover:scale-105"
                      >
                        Generate Documents
                      </button>
                      <button
                        type="button"
                        onClick={() => setShowJobMatchDialog(false)}
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 transition-colors duration-200 sm:mt-0 sm:w-auto"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* Document Generation Section */}
          <div className="border-t border-gray-200 p-6">
            {!applicationData && (
              hasResume ? (
                <button 
                  onClick={generateDocuments}
                  disabled={isGeneratingDocuments || isAnalyzing}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-lg w-full sm:w-auto transition-all duration-300 transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 mb-4 disabled:opacity-50 shadow-md hover:shadow-lg"
                >
                  {isAnalyzing ? (
                    <span className="flex items-center justify-center">
                      <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Analyzing Job Match...
                    </span>
                  ) : isGeneratingDocuments ? (
                    <span className="flex items-center justify-center">
                      <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Generating Documents...
                    </span>
                  ) : (
                    <span className="flex items-center">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                      Generate Tailored Documents
                    </span>
                  )}
                </button>
              ) : (
                <button 
                  onClick={() => navigate('/profile')}
                  className="bg-gray-500 text-white font-bold py-3 px-6 rounded-lg w-full sm:w-auto transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50 mb-4 shadow-md"
                >
                  Please go to your profile first and upload a resume
                </button>
              )
            )}

            {(isAnalyzing || isGeneratingDocuments) && (
              <div className="mt-6 w-full max-w-md mx-auto animate-fade-in">
                <div className="mb-2 text-sm font-medium text-gray-700">{loadingMessage}</div>
                <div className="w-full bg-gray-200 rounded-full h-2.5 overflow-hidden">
                  <div 
                    className={`h-2.5 rounded-full transition-all duration-500 ease-out ${
                      isAnalyzing ? 'bg-yellow-500' : 'bg-blue-600'
                    }`}
                    style={{ width: `${loadingProgress}%` }}
                  ></div>
                </div>
                <div className="mt-2 text-xs text-gray-500 text-right">{Math.round(loadingProgress)}%</div>
              </div>
            )}

            {/* Generated Documents Section */}
            {applicationData && (
              <div className="mt-8 animate-fade-in">
                <h2 className="text-2xl sm:text-4xl font-bold mb-8 text-center underline">Generated Documents</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                  <div className="animate-slide-in" style={{ animationDelay: '200ms' }}>
                    <div className="flex justify-between items-center mb-4">
                      <h3 className="text-xl font-semibold">Resume</h3>
                      <button
                        onClick={regenerateResume}
                        disabled={isRegeneratingResume}
                        className="bg-red-700 hover:bg-red-900 text-white font-bold py-2 px-4 rounded text-sm transition-all duration-200 disabled:opacity-50 hover:scale-105 flex items-center space-x-2 shadow-md"
                      >
                        {isRegeneratingResume ? (
                          <span className="flex items-center">
                            <svg className="animate-spin -ml-1 mr-2 h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                            Regenerating...
                          </span>
                        ) : (
                          <>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                            </svg>
                            <span>Regenerate Resume</span>
                          </>
                        )}
                      </button>
                    </div>
                    <div className="space-y-4">
                      {applicationData.png_images.map((image, index) => (
                        <div 
                          key={index} 
                          className="transform transition-all duration-300 hover:scale-105"
                        >
                          <img 
                            src={image} 
                            alt={`Resume page ${index + 1}`} 
                            className="w-full h-auto object-contain rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300" 
                            loading="lazy"
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                  
                  <div className="animate-slide-in" style={{ animationDelay: '400ms' }}>
                    <div className="flex justify-between items-center mb-4">
                      <h3 className="text-xl font-semibold">Cover Letter</h3>
                      <button
                        onClick={regenerateCoverLetter}
                        disabled={isRegeneratingCoverLetter}
                        className="bg-red-700 hover:bg-red-900 text-white font-bold py-2 px-4 rounded text-sm transition-all duration-200 disabled:opacity-50 hover:scale-105 flex items-center space-x-2 shadow-md"
                      >
                        {isRegeneratingCoverLetter ? (
                          <span className="flex items-center">
                            <svg className="animate-spin -ml-1 mr-2 h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                            Regenerating...
                          </span>
                        ) : (
                          <>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                            </svg>
                            <span>Regenerate Cover Letter</span>
                          </>
                        )}
                      </button>
                    </div>
                    <div className="bg-gray-100 p-6 rounded-lg shadow-md hover:shadow-xl transition-all duration-300 transform hover:scale-102">
                      <pre className="whitespace-pre-wrap font-sans text-gray-800">{coverLetterContent}</pre>
                    </div>
                  </div>
                </div>

                <div className="mt-8 flex flex-wrap justify-center gap-4 animate-slide-up" style={{ animationDelay: '600ms' }}>
                  <a 
                    href={applicationData.pdf_file} 
                    download 
                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-6 rounded transition-all duration-200 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-50 shadow-md hover:shadow-lg flex items-center space-x-2"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                    </svg>
                    <span>Download Resume PDF</span>
                  </a>
                  <button 
                    onClick={downloadCoverLetter}
                    disabled={isDownloadingCoverLetter}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded transition-all duration-200 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50 shadow-md hover:shadow-lg flex items-center space-x-2"
                  >
                    {isDownloadingCoverLetter ? (
                      <span className="flex items-center">
                        <svg className="animate-spin -ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        <span>Downloading...</span>
                      </span>
                    ) : (
                      <>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                        </svg>
                        <span>Download Cover Letter</span>
                      </>
                    )}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default JobDetailsPage;