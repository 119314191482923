import HeroSection from '../features/herosection/HeroSection.js';
import React, { useState } from 'react';
import TimelineFeature from '../features/featuresection/TimelineFeature.js';
import FAQ from '../features/faqsection/FaqSection.js';
import Stats from '../features/stats/Stats.js';
import Footer from '../features/footer/Footer.js';

export default function Home() {
  return (
    <>
    <HeroSection/>
    <Stats />
    <Footer />
    </>
  )
}