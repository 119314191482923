import React, { useState, useEffect } from 'react';
import { Info, ChevronDown, ChevronUp, CheckCircle2 } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../UserContext';

const WelcomeBannerDashboard = ({ isLoggedIn = false, userName = '' }) => {
  const { profile } = useUser();
  const [currentQuote, setCurrentQuote] = useState('');
  const [charIndex, setCharIndex] = useState(0);
  const [showWhyInfo, setShowWhyInfo] = useState(false);
  const navigate = useNavigate();

  const getQuotes = (name) => [
    isLoggedIn ? `${name}, you are one step away from landing your dream visa sponsored job` : "You are one step away from landing your dream visa sponsored job",
    isLoggedIn ? `${name}, your international experience is your superpower` : "Your international experience is your superpower",
    isLoggedIn ? `${name}, every application brings you closer to your UK career goals` : "Every application brings you closer to your UK career goals",
    isLoggedIn ? `${name}, turn your UK dreams into reality - keep pushing forward` : "Turn your UK dreams into reality - keep pushing forward",
    isLoggedIn ? `${name}, your unique perspective is valuable to UK employers` : "Your unique perspective is valuable to UK employers",
    isLoggedIn ? `${name}, success is not just about skills, it's about persistence` : "Success is not just about skills, it's about persistence",
    isLoggedIn ? `${name}, the UK industries need diverse talent like yours` : "The UK industries needs diverse talent like yours",
    isLoggedIn ? `${name}, your global mindset is your competitive advantage` : "Your global mindset is your competitive advantage",
    isLoggedIn ? `${name}, every 'no' brings you closer to your perfect 'yes'` : "Every 'no' brings you closer to your perfect 'yes'",
    isLoggedIn ? `${name}, your determination today shapes your UK future tomorrow` : "Your determination today shapes your UK future tomorrow"
  ];

  const [quoteIndex, setQuoteIndex] = useState(0);
  const quotes = getQuotes(userName);

  useEffect(() => {
    if (charIndex < quotes[quoteIndex].length) {
      const typingTimer = setTimeout(() => {
        setCurrentQuote(prev => prev + quotes[quoteIndex][charIndex]);
        setCharIndex(charIndex + 1);
      }, 50);
      return () => clearTimeout(typingTimer);
    } else {
      const nextQuoteTimer = setTimeout(() => {
        setQuoteIndex((prevIndex) => (prevIndex + 1) % quotes.length);
        setCurrentQuote('');
        setCharIndex(0);
      }, 3000);
      return () => clearTimeout(nextQuoteTimer);
    }
  }, [charIndex, quoteIndex, quotes]);

  const renderStatusMessage = () => {
    if (!isLoggedIn) {
      return (
        <div className="w-full max-w-2xl space-y-2">
          <div className="bg-red-50 rounded-lg p-3 sm:p-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-2 flex-shrink min-w-0">
                <Info className="text-red-500 w-4 h-4 sm:w-5 sm:h-5 flex-shrink-0" />
                <span className="text-red-700 font-medium text-sm sm:text-base truncate">
                  AI Job Match is not available
                </span>
              </div>
              <button
                onClick={() => setShowWhyInfo(!showWhyInfo)}
                className="flex items-center justify-end space-x-1 text-red-700 hover:text-red-800 text-sm sm:text-base flex-shrink-0 ml-2"
              >
                <span>See why?</span>
                {showWhyInfo ? (
                  <ChevronUp className="w-4 h-4" />
                ) : (
                  <ChevronDown className="w-4 h-4" />
                )}
              </button>
            </div>
          </div>
          
          {showWhyInfo && (
            <div className="bg-white border border-red-100 rounded-lg p-3 sm:p-4 shadow-sm">
              <p className="text-gray-700 text-sm sm:text-base leading-relaxed">
                AI Job Match is an exclusive feature for registered members who have completed their profiles. 
                By uploading your profile, our AI system can analyze your experience and skills to provide 
                personalized job matches that align with your career goals and visa sponsorship requirements.
              </p>
            </div>
          )}
        </div>
      );
    } else if (!profile?.resume) {
      return (
        <div className="w-full max-w-2xl">
          <div className="bg-yellow-50 rounded-lg p-3 sm:p-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-2">
                <Info className="text-yellow-500 w-4 h-4 sm:w-5 sm:h-5 flex-shrink-0" />
                <span className="text-yellow-700 font-medium text-sm sm:text-base">
                  Please upload your CV to access AI Job Match
                </span>
              </div>
              <button
                onClick={() => navigate('/profile')}
                className="bg-yellow-500 hover:bg-yellow-600 text-white text-sm font-medium px-4 py-2 rounded-full transition-all duration-200 flex items-center space-x-2"
              >
                Upload CV
              </button>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="w-full max-w-2xl">
          <div className="bg-green-50 rounded-lg p-3 sm:p-4">
            <div className="flex items-center space-x-2">
              <CheckCircle2 className="text-green-500 w-4 h-4 sm:w-5 sm:h-5 flex-shrink-0" />
              <span className="text-green-700 font-medium text-sm sm:text-base">
                AI Job Match is available! Search jobs to see your match score.
              </span>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="flex flex-col items-center justify-center w-full px-4 py-3 sm:p-6 space-y-3 sm:space-y-4">
      <div className="min-h-12 sm:min-h-16 md:min-h-20 flex items-center justify-center px-2 sm:px-4">
        <p className="text-sm sm:text-base md:text-xl text-center font-medium text-gray-700">
          {currentQuote}
          <span className="animate-pulse">|</span>
        </p>
      </div>

      {renderStatusMessage()}
    </div>
  );
};

export default WelcomeBannerDashboard;