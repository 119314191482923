import React, { useEffect, useState, useCallback } from 'react';
import { useNotification } from '../../NotificationContext';
import { X } from 'lucide-react';

const Notification = () => {
  const { notification } = useNotification();
  const [isVisible, setIsVisible] = useState(false);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const [slideDirection, setSlideDirection] = useState('');

  // Minimum swipe distance in pixels
  const minSwipeDistance = 50;

  const onTouchStart = (e) => {
    setTouchStart({
      x: e.targetTouches[0].clientX,
      y: e.targetTouches[0].clientY
    });
  };

  const onTouchMove = (e) => {
    setTouchEnd({
      x: e.targetTouches[0].clientX,
      y: e.targetTouches[0].clientY
    });
  };

  const onTouchEnd = useCallback(() => {
    if (!touchStart || !touchEnd) return;

    const distanceX = touchStart.x - touchEnd.x;
    const distanceY = touchStart.y - touchEnd.y;
    const isHorizontalSwipe = Math.abs(distanceX) > Math.abs(distanceY);

    if (Math.abs(distanceX) >= minSwipeDistance || Math.abs(distanceY) >= minSwipeDistance) {
      if (isHorizontalSwipe) {
        setSlideDirection(distanceX > 0 ? 'left' : 'right');
      } else {
        setSlideDirection(distanceY > 0 ? 'up' : 'down');
      }
      setTimeout(() => {
        setIsVisible(false);
      }, 300);
    }
  }, [touchStart, touchEnd]);

  const handleClick = () => {
    setIsVisible(false);
  };

  useEffect(() => {
    if (notification) {
      setIsVisible(true);
      setSlideDirection('');
      const timer = setTimeout(() => {
        setIsVisible(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [notification]);

  if (!notification || !isVisible) return null;

  const getNotificationStyles = (type) => {
    switch (type) {
      case 'success':
        return {
          container: 'bg-emerald-50 border-emerald-200',
          icon: 'text-emerald-500',
          text: 'text-emerald-800',
        };
      case 'error':
        return {
          container: 'bg-red-50 border-red-200',
          icon: 'text-red-500',
          text: 'text-red-800',
        };
      default:
        return {
          container: 'bg-blue-50 border-blue-200',
          icon: 'text-blue-500',
          text: 'text-blue-800',
        };
    }
  };

  const getIcon = (type) => {
    switch (type) {
      case 'success':
        return (
          <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              strokeWidth={2} 
              d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" 
            />
          </svg>
        );
      case 'error':
        return (
          <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              strokeWidth={2} 
              d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" 
            />
          </svg>
        );
      default:
        return (
          <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              strokeWidth={2} 
              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" 
            />
          </svg>
        );
    }
  };

  const getAnimationClass = () => {
    if (!isVisible) {
      switch (slideDirection) {
        case 'left':
          return 'animate-slide-out-left';
        case 'right':
          return 'animate-slide-out-right';
        case 'up':
          return 'animate-slide-out-up';
        case 'down':
          return 'animate-slide-out-down';
        default:
          return 'animate-notification-slide-out';
      }
    }
    return 'animate-notification-slide-in';
  };

  const styles = getNotificationStyles(notification.type);

  return (
    <div 
      className="fixed inset-x-0 top-5 flex justify-center items-start z-50 px-4"
      aria-live="assertive"
      role="alert"
    >
      <div
        className={`
          ${getAnimationClass()}
          max-w-sm w-full flex items-center gap-3 p-4 rounded-lg shadow-lg border
          backdrop-blur-sm ${styles.container}
          transform transition-all duration-300 cursor-pointer
        `}
        onClick={handleClick}
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
      >
        <div className={`flex-shrink-0 ${styles.icon}`}>
          {getIcon(notification.type)}
        </div>
        
        <div className="flex-1 min-w-0">
          <p className={`text-sm font-medium ${styles.text}`}>
            {notification.message}
          </p>
        </div>

        <button
          onClick={(e) => {
            e.stopPropagation();
            handleClick();
          }}
          className={`p-1 rounded-full hover:bg-gray-200 transition-colors ${styles.icon}`}
          aria-label="Close notification"
        >
          <X className="w-4 h-4" />
        </button>
      </div>
    </div>
  );
};

export default Notification;