import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Lock, Eye, EyeOff, ArrowRight, Check } from 'lucide-react';
import { useNotification } from "../../NotificationContext";
import Navbar from '../navbar/Navbar';
import Footer from '../footer/Footer';
import api from '../../utils/api';

const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { token } = useParams();
  const navigate = useNavigate();
  const { showNotification } = useNotification();

  const validatePassword = (password) => {
    const minLength = password.length >= 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    return {
      minLength,
      hasUpperCase,
      hasLowerCase,
      hasNumber,
      hasSpecialChar,
      isValid: minLength && hasUpperCase && hasLowerCase && hasNumber && hasSpecialChar
    };
  };

  const getPasswordStrength = () => {
    const validation = validatePassword(password);
    const criteriaCount = Object.values(validation).filter(Boolean).length - 1; // -1 to exclude isValid
    if (password.length === 0) return { strength: 0, text: '' };
    if (criteriaCount <= 2) return { strength: 25, text: 'Weak' };
    if (criteriaCount === 3) return { strength: 50, text: 'Medium' };
    if (criteriaCount === 4) return { strength: 75, text: 'Strong' };
    return { strength: 100, text: 'Very Strong' };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      showNotification('Passwords do not match.', 'error');
      return;
    }

    if (!validatePassword(password).isValid) {
      showNotification('Password does not meet the requirements.', 'error');
      return;
    }

    setIsLoading(true);
    try {
      await api.post(`/auth/reset-password/${token}`, { password });
      showNotification('Password reset successful! Please login.', 'success');
      setTimeout(() => navigate('/login'), 1500);
    } catch (error) {
      showNotification(error.response?.data?.error || 'An error occurred. Please try again.', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const passwordStrength = getPasswordStrength();

  return (
    <div className="min-h-screen flex flex-col">
      <Navbar />
      
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="flex-grow flex items-center justify-center px-4 py-12"
      >
        <div className="max-w-md w-full space-y-8 bg-white p-8 rounded-xl shadow-lg">
          <div className="text-center">
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ delay: 0.2 }}
              className="mx-auto h-12 w-12 bg-blue-100 rounded-full flex items-center justify-center"
            >
              <Lock className="h-6 w-6 text-blue-600" />
            </motion.div>
            <motion.h2
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.3 }}
              className="mt-6 text-3xl font-extrabold text-gray-900"
            >
              Reset Password
            </motion.h2>
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.4 }}
              className="mt-2 text-sm text-gray-600"
            >
              Please enter your new password below
            </motion.p>
          </div>

          <motion.form
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5 }}
            onSubmit={handleSubmit}
            className="mt-8 space-y-6"
          >
            <div className="space-y-4">
              <div className="relative">
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                  New Password
                </label>
                <div className="mt-1 relative">
                  <input
                    id="password"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="appearance-none rounded-lg relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                  <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    className="absolute inset-y-0 right-0 pr-3 flex items-center"
                  >
                    {showPassword ? (
                      <EyeOff className="h-5 w-5 text-gray-400" />
                    ) : (
                      <Eye className="h-5 w-5 text-gray-400" />
                    )}
                  </button>
                </div>
              </div>

              <div className="relative">
                <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">
                  Confirm Password
                </label>
                <div className="mt-1 relative">
                  <input
                    id="confirmPassword"
                    name="confirmPassword"
                    type={showConfirmPassword ? "text" : "password"}
                    required
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className="appearance-none rounded-lg relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                  <button
                    type="button"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    className="absolute inset-y-0 right-0 pr-3 flex items-center"
                  >
                    {showConfirmPassword ? (
                      <EyeOff className="h-5 w-5 text-gray-400" />
                    ) : (
                      <Eye className="h-5 w-5 text-gray-400" />
                    )}
                  </button>
                </div>
              </div>

              {password && (
                <motion.div
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: 'auto' }}
                  className="space-y-2"
                >
                  <div className="flex justify-between items-center">
                    <span className="text-sm text-gray-600">Password strength:</span>
                    <span className={`text-sm font-medium
                      ${passwordStrength.strength <= 25 ? 'text-red-500' :
                        passwordStrength.strength <= 50 ? 'text-yellow-500' :
                        passwordStrength.strength <= 75 ? 'text-blue-500' :
                        'text-green-500'}`}
                    >
                      {passwordStrength.text}
                    </span>
                  </div>
                  <div className="h-2 bg-gray-200 rounded-full">
                    <motion.div
                      initial={{ width: 0 }}
                      animate={{ width: `${passwordStrength.strength}%` }}
                      className={`h-full rounded-full transition-all duration-300
                        ${passwordStrength.strength <= 25 ? 'bg-red-500' :
                          passwordStrength.strength <= 50 ? 'bg-yellow-500' :
                          passwordStrength.strength <= 75 ? 'bg-blue-500' :
                          'bg-green-500'}`}
                    />
                  </div>
                  <ul className="text-sm text-gray-600 space-y-1">
                    <li className={validatePassword(password).minLength ? 'text-green-600' : ''}>
                      • At least 8 characters long
                    </li>
                    <li className={validatePassword(password).hasUpperCase ? 'text-green-600' : ''}>
                      • Contains uppercase letter
                    </li>
                    <li className={validatePassword(password).hasLowerCase ? 'text-green-600' : ''}>
                      • Contains lowercase letter
                    </li>
                    <li className={validatePassword(password).hasNumber ? 'text-green-600' : ''}>
                      • Contains number
                    </li>
                    <li className={validatePassword(password).hasSpecialChar ? 'text-green-600' : ''}>
                      • Contains special character
                    </li>
                  </ul>
                </motion.div>
              )}
            </div>

            <div>
              <button
                type="submit"
                disabled={isLoading || !validatePassword(password).isValid || password !== confirmPassword}
                className={`group relative w-full flex justify-center py-3 px-4 border border-transparent text-sm font-medium rounded-lg text-white 
                  ${(!validatePassword(password).isValid || password !== confirmPassword)
                    ? 'bg-gray-400 cursor-not-allowed'
                    : 'bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                  } transition-all duration-200 ease-in-out`}
              >
                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  {isLoading ? (
                    <motion.div
                      animate={{ rotate: 360 }}
                      transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
                    >
                      <Lock className="h-5 w-5 text-blue-300" />
                    </motion.div>
                  ) : (
                    <ArrowRight className="h-5 w-5 text-blue-300" />
                  )}
                </span>
                {isLoading ? 'Resetting...' : 'Reset Password'}
              </button>
            </div>
          </motion.form>
        </div>
      </motion.div>
      
      <Footer />
    </div>
  );
};

export default ResetPassword;