import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Navbar from '../features/navbar/Navbar';
import Footer from '../features/footer/Footer';
import { useNotification } from "../NotificationContext";
import api from '../utils/api';

const ManualJobTailoring = () => {
  const [jobDescription, setJobDescription] = useState('');
  const [isGeneratingDocuments, setIsGeneratingDocuments] = useState(false);
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [isRegeneratingResume, setIsRegeneratingResume] = useState(false);
  const [isRegeneratingCoverLetter, setIsRegeneratingCoverLetter] = useState(false);
  const [applicationData, setApplicationData] = useState(null);
  const [coverLetterContent, setCoverLetterContent] = useState('');
  const [hasResume, setHasResume] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [showJobMatchDialog, setShowJobMatchDialog] = useState(false);
  const [jobMatchScore, setJobMatchScore] = useState(null);
  const [jobMatchReasons, setJobMatchReasons] = useState([]);
  const [showMatchDetails, setShowMatchDetails] = useState(false);
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const { showNotification } = useNotification();
  const navigate = useNavigate();

  const careerMessages = [
    "Crafting your professional story...",
    "Highlighting your unique skills...",
    "Tailoring your experience to the role...",
    "Polishing your career achievements...",
    "Preparing to showcase your potential..."
  ];

  const analyzeMessages = [
    "Analyzing job requirements...",
    "Comparing skills and experience...",
    "Evaluating qualifications...",
    "Calculating match score...",
    "Preparing detailed analysis..."
  ];

  useEffect(() => {
    checkUserResume();
    // Add page load animation after a small delay
    setTimeout(() => setIsPageLoaded(true), 100);
  }, []);

  const getMatchScoreColor = (score) => {
    if (score >= 80) return {
      bg: 'bg-green-100',
      text: 'text-green-600',
      border: 'border-green-200',
      hover: 'hover:bg-green-200',
      ring: 'ring-green-400'
    };
    if (score >= 60) return {
      bg: 'bg-yellow-100',
      text: 'text-yellow-600',
      border: 'border-yellow-200',
      hover: 'hover:bg-yellow-200',
      ring: 'ring-yellow-400'
    };
    return {
      bg: 'bg-red-100',
      text: 'text-red-600',
      border: 'border-red-200',
      hover: 'hover:bg-red-200',
      ring: 'ring-red-400'
    };
  };

  const checkUserResume = async () => {
    try {
      const response = await api.get('/user/has-resume');
      setHasResume(response.data.hasResume);
    } catch (error) {
      console.error('Error checking user resume:', error);
      setHasResume(false);
    }
  };

  const resetForm = () => {
    setJobDescription('');
    setApplicationData(null);
    setCoverLetterContent('');
    setJobMatchScore(null);
    setJobMatchReasons([]);
    setShowMatchDetails(false);
  };

  const startAnalysisProgress = () => {
    setLoadingProgress(0);
    const updateProgress = () => {
      setLoadingProgress(prev => {
        const newProgress = prev + Math.random() * 20;
        return newProgress > 90 ? 90 : newProgress;
      });
      setLoadingMessage(analyzeMessages[Math.floor(Math.random() * analyzeMessages.length)]);
    };
    return setInterval(updateProgress, 1000);
  };

  const analyzeJobMatch = async () => {
    setIsAnalyzing(true);
    const progressInterval = startAnalysisProgress();

    try {
      const response = await api.post('/manual/analyze-match', { jobDescription });
      setLoadingProgress(100);
      await new Promise(resolve => setTimeout(resolve, 500)); // Smooth transition
      setJobMatchScore(response.data.score);
      setJobMatchReasons(response.data.reasons);
      setShowJobMatchDialog(true);
    } catch (error) {
      console.error('Error analyzing job match:', error);
      showNotification('Failed to analyze job match. Please try again.', 'error');
    } finally {
      clearInterval(progressInterval);
      setIsAnalyzing(false);
      setLoadingProgress(0);
    }
  };

  const generateDocuments = async () => {
    if (!jobDescription.trim()) {
      showNotification('Please enter a job description', 'error');
      return;
    }

    try {
      await analyzeJobMatch();
    } catch (error) {
      console.error('Error in initial job match analysis:', error);
    }
  };

  const proceedWithGeneration = async () => {
    setShowJobMatchDialog(false);
    setIsGeneratingDocuments(true);
    setLoadingProgress(0);
    
    const updateProgress = () => {
      setLoadingProgress(prev => {
        const newProgress = prev + Math.random() * 15;
        return newProgress > 90 ? 90 : newProgress;
      });
      setLoadingMessage(careerMessages[Math.floor(Math.random() * careerMessages.length)]);
    };

    const progressInterval = setInterval(updateProgress, 2500);

    try {
      const resumeResponse = await api.post('/manual/apply', 
        { jobDescription }
      );
      
      const coverLetterResponse = await api.post('/manual/generate-cover-letter', 
        { jobDescription }
      );

      // Add slight delay for smoother transition
      setTimeout(() => {
        setApplicationData(resumeResponse.data);
        setCoverLetterContent(coverLetterResponse.data.content);
        showNotification('Documents generated successfully!', 'success');
      }, 500);

    } catch (error) {
      console.error('Error generating documents:', error);
      const errorMessage = error.response?.data?.error || 'An error occurred while generating documents';
      showNotification(errorMessage, 'error');
    } finally {
      clearInterval(progressInterval);
      setLoadingProgress(100);
      setTimeout(() => setIsGeneratingDocuments(false), 500); // Smooth transition
    }
  };

  const regenerateResume = async () => {
    setIsRegeneratingResume(true);
    try {
      const resumeResponse = await api.post('/manual/apply', 
        { jobDescription }
      );
      
      // Add animation delay
      setTimeout(() => {
        setApplicationData(resumeResponse.data);
        showNotification('Resume regenerated successfully!', 'success');
      }, 300);

    } catch (error) {
      console.error('Error regenerating resume:', error);
      showNotification('Failed to regenerate resume. Please try again.', 'error');
    } finally {
      setTimeout(() => setIsRegeneratingResume(false), 300);
    }
  };

  const regenerateCoverLetter = async () => {
    setIsRegeneratingCoverLetter(true);
    try {
      const coverLetterResponse = await api.post('/manual/generate-cover-letter', 
        { jobDescription }
      );

      // Add animation delay
      setTimeout(() => {
        setCoverLetterContent(coverLetterResponse.data.content);
        showNotification('Cover letter regenerated successfully!', 'success');
      }, 300);

    } catch (error) {
      console.error('Error regenerating cover letter:', error);
      showNotification('Failed to regenerate cover letter. Please try again.', 'error');
    } finally {
      setTimeout(() => setIsRegeneratingCoverLetter(false), 300);
    }
  };

  const downloadCoverLetter = async () => {
    try {
      const response = await api.get('/manual/cover-letter', {
        responseType: 'arraybuffer',
        headers: { 
          'Accept': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        }
      });
      
      const blob = new Blob([response.data], { 
        type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' 
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'cover_letter.docx');
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);

      showNotification('Cover letter downloaded successfully!', 'success');
    } catch (error) {
      console.error('Error downloading cover letter:', error);
      showNotification('Failed to download cover letter. Please try again.', 'error');
    }
  };

  return (
    <div className={`min-h-screen flex flex-col bg-gray-50 transition-opacity duration-500 ${isPageLoaded ? 'opacity-100' : 'opacity-0'}`}>
      <div className="pb-20">
        <Navbar />
      </div>
      <main className="flex-grow container mx-auto px-4 py-8">
        <div className="max-w-4xl mx-auto space-y-6">
          <div className="text-center space-y-4 animate-fade-in">
            <h1 className="text-3xl md:text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-blue-600">
              Manual Job Description Tailoring
            </h1>
            <p className="text-gray-600">Customize your resume and cover letter for any job description</p>
            <Link 
              to="/jobs" 
              className="inline-flex items-center text-blue-600 hover:text-blue-800 transition-colors duration-200 group"
            >
              <span>Looking for sponsored jobs instead?</span>
              <svg 
                xmlns="http://www.w3.org/2000/svg" 
                className="h-4 w-4 ml-1 transform transition-transform duration-200 group-hover:translate-x-1" 
                fill="none" 
                viewBox="0 0 24 24" 
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
              </svg>
            </Link>
          </div>
          
          <div className="bg-white rounded-xl shadow-xl p-6 md:p-8 transform transition-all duration-300 hover:shadow-2xl animate-slide-up">
            <h2 className="text-xl font-semibold mb-4 text-gray-800">Enter Job Description</h2>
            <textarea
              className="w-full h-64 p-4 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent bg-gray-50 transition-all duration-200"
              placeholder="Paste the job description here..."
              value={jobDescription}
              onChange={(e) => setJobDescription(e.target.value)}
              disabled={isGeneratingDocuments || isAnalyzing}
            />
            
            {!applicationData && (
              hasResume ? (
                <button 
                  onClick={generateDocuments}
                  disabled={isGeneratingDocuments || isAnalyzing}
                  className="mt-4 w-full bg-gradient-to-r from-blue-600 to-blue-600 text-white font-bold py-3 px-6 rounded-lg transition-all duration-300 transform hover:scale-[1.02] focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed disabled:transform-none"
                >
                  {isAnalyzing ? (
                    <span className="flex items-center justify-center">
                      <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Analyzing Job Match...
                    </span>
                  ) : isGeneratingDocuments ? (
                    <span className="flex items-center justify-center">
                      <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Generating Documents...
                    </span>
                  ) : (
                    <span className="flex items-center justify-center">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                      Generate Tailored Documents
                    </span>
                  )}
                </button>
              ) : (
                <button 
                  onClick={() => navigate('/profile')}
                  className="mt-4 w-full bg-gray-500 text-white font-bold py-3 px-6 rounded-lg transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50 hover:bg-gray-600"
                >
                  <span className="flex items-center justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                    </svg>
                    Please upload your resume first
                  </span>
                </button>
              )
            )}
          </div>
          {/* Progress Bars */}
          {(isAnalyzing || isGeneratingDocuments) && (
            <div className="mt-6 w-full max-w-md mx-auto animate-fade-in">
              <div className="mb-2 text-sm font-medium text-gray-700 flex items-center space-x-2">
                <div className="w-4 h-4 rounded-full animate-pulse" 
                  style={{ backgroundColor: isAnalyzing ? '#EAB308' : '#2563EB' }}
                ></div>
                <span>{loadingMessage}</span>
              </div>
              <div className="w-full bg-gray-200 rounded-full h-2.5 overflow-hidden">
                <div 
                  className={`h-2.5 rounded-full transition-all duration-500 ease-out ${
                    isAnalyzing ? 'bg-yellow-500' : 'bg-blue-600'
                  }`}
                  style={{ width: `${loadingProgress}%` }}
                ></div>
              </div>
              <div className="mt-2 text-xs text-gray-500 text-right">{Math.round(loadingProgress)}%</div>
            </div>
          )}

          {/* Job Match Dialog */}
          {showJobMatchDialog && (
            <div className="fixed inset-0 z-50">
              <div 
                className="fixed inset-0 bg-black bg-opacity-50 transition-opacity animate-fade-in backdrop-blur-sm" 
                onClick={() => setShowJobMatchDialog(false)}
              ></div>
              <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4">
                  <div className="relative transform overflow-hidden rounded-2xl bg-white text-left shadow-xl transition-all sm:w-full sm:max-w-lg animate-slide-up">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                      <div className="flex flex-col items-center">
                        <h3 className="text-2xl font-semibold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-blue-600">
                          Job Match Analysis
                        </h3>
                        
                        <div className="relative w-48 h-48 mb-6">
                          <svg className="transform -rotate-90 w-48 h-48">
                            <circle
                              cx="96"
                              cy="96"
                              r="88"
                              className="stroke-current text-gray-200"
                              strokeWidth="12"
                              fill="transparent"
                            />
                            <circle
                              cx="96"
                              cy="96"
                              r="88"
                              className={`stroke-current ${jobMatchScore >= 70 ? 'text-green-500' : 'text-yellow-500'} transition-all duration-1000 ease-out`}
                              strokeWidth="12"
                              fill="transparent"
                              strokeDasharray={553}
                              strokeDashoffset={553 - (553 * jobMatchScore) / 100}
                              style={{ transition: 'stroke-dashoffset 1s ease-out' }}
                            />
                          </svg>
                          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
                            <span className={`text-4xl font-bold ${jobMatchScore >= 70 ? 'text-green-600' : 'text-yellow-600'} animate-number`}>
                              {jobMatchScore}%
                            </span>
                          </div>
                        </div>

                        <button
                          onClick={() => setShowMatchDetails(!showMatchDetails)}
                          className="mb-4 bg-gradient-to-r from-yellow-400 to-yellow-500 text-white px-4 py-2 rounded-full transition-all duration-200 flex items-center space-x-2 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-yellow-400 focus:ring-opacity-50 shadow-md hover:shadow-lg"
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                          </svg>
                          <span className="font-medium">{showMatchDetails ? 'Hide Details' : 'View Details'}</span>
                        </button>

                        {showMatchDetails && (
                          <div className="w-full bg-gray-50 p-4 rounded-lg animate-fade-in border border-gray-200">
                            <h4 className="font-semibold mb-3 text-lg">
                              {jobMatchScore >= 70 ? 'Why this job is a good match:' : 'Areas of consideration:'}
                            </h4>
                            <ul className="list-disc pl-5 space-y-2">
                              {jobMatchReasons.map((reason, index) => (
                                <li 
                                  key={index} 
                                  className="text-sm text-gray-700 animate-slide-in"
                                  style={{ animationDelay: `${index * 100}ms` }}
                                >
                                  {reason}
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 border-t">
                      <button
                        type="button"
                        onClick={proceedWithGeneration}
                        className="inline-flex w-full justify-center rounded-lg bg-gradient-to-r from-blue-600 to-blue-600 px-4 py-2.5 text-sm font-semibold text-white shadow-sm hover:from-blue-500 hover:to-blue-500 transition-all duration-200 sm:ml-3 sm:w-auto hover:scale-105"
                      >
                        Generate Documents
                      </button>
                      <button
                        type="button"
                        onClick={() => setShowJobMatchDialog(false)}
                        className="mt-3 inline-flex w-full justify-center rounded-lg bg-white px-4 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 transition-colors duration-200 sm:mt-0 sm:w-auto"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Generated Documents Section */}
          {applicationData && (
            <div className="mt-8 animate-fade-in space-y-8">
              <div className="flex flex-col sm:flex-row justify-between items-center gap-4 bg-white p-6 rounded-xl shadow-lg">
                <h2 className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-blue-600">
                  Generated Documents
                </h2>
                <button
                  onClick={resetForm}
                  className="bg-gradient-to-r from-blue-600 to-blue-600 text-white font-bold py-2.5 px-5 rounded-lg transition-all duration-200 hover:scale-105 focus:outline-none shadow-md hover:shadow-lg flex items-center space-x-2"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                  </svg>
                  <span>Generate For Another Job</span>
                </button>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                {/* Resume Section */}
                <div className="animate-slide-in bg-white p-6 rounded-xl shadow-lg" style={{ animationDelay: '200ms' }}>
                  <div className="flex justify-between items-center mb-4">
                    <h3 className="text-xl font-semibold text-gray-800">Resume</h3>
                    <button
                      onClick={regenerateResume}
                      disabled={isRegeneratingResume}
                      className="bg-gradient-to-r from-red-600 to-red-700 hover:from-red-500 hover:to-red-600 text-white font-bold py-2 px-4 rounded-lg transition-all duration-200 disabled:opacity-50 hover:scale-105 focus:outline-none shadow-md hover:shadow-lg flex items-center space-x-2"
                    >
                      {isRegeneratingResume ? (
                        <span className="flex items-center">
                          <svg className="animate-spin -ml-1 mr-2 h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                          </svg>
                          <span>Regenerating...</span>
                        </span>
                      ) : (
                        <>
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                          </svg>
                          <span>Regenerate</span>
                        </>
                      )}
                    </button>
                  </div>
                  <div className="space-y-4">
                    {applicationData.png_images.map((image, index) => (
                      <div 
                        key={index}
                        className="transform transition-all duration-300 hover:scale-105 rounded-lg overflow-hidden shadow-md hover:shadow-xl"
                      >
                        <img 
                          src={image} 
                          alt={`Resume page ${index + 1}`} 
                          className="w-full h-auto object-contain" 
                          loading="lazy"
                        />
                      </div>
                    ))}
                  </div>
                </div>

                {/* Cover Letter Section */}
                <div className="animate-slide-in bg-white p-6 rounded-xl shadow-lg" style={{ animationDelay: '400ms' }}>
                  <div className="flex justify-between items-center mb-4">
                    <h3 className="text-xl font-semibold text-gray-800">Cover Letter</h3>
                    <button
                      onClick={regenerateCoverLetter}
                      disabled={isRegeneratingCoverLetter}
                      className="bg-gradient-to-r from-red-600 to-red-700 hover:from-red-500 hover:to-red-600 text-white font-bold py-2 px-4 rounded-lg transition-all duration-200 disabled:opacity-50 hover:scale-105 focus:outline-none shadow-md hover:shadow-lg flex items-center space-x-2"
                    >
                      {isRegeneratingCoverLetter ? (
                        <span className="flex items-center">
                          <svg className="animate-spin -ml-1 mr-2 h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                          </svg>
                          <span>Regenerating...</span>
                        </span>
                      ) : (
                        <>
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                          </svg>
                          <span>Regenerate</span>
                        </>
                      )}
                    </button>
                  </div>
                  <div className="bg-gray-50 p-6 rounded-lg shadow-inner hover:bg-gray-100 transition-all duration-300">
                    <pre className="whitespace-pre-wrap font-sans text-gray-800">{coverLetterContent}</pre>
                  </div>
                </div>
              </div>

              {/* Download Buttons */}
              <div className="flex flex-wrap justify-center gap-4 animate-slide-up py-6">
                <a 
                  href={applicationData.pdf_file} 
                  download 
                  className="bg-gradient-to-r from-green-600 to-green-700 hover:from-green-500 hover:to-green-600 text-white font-bold py-2.5 px-6 rounded-lg transition-all duration-200 hover:scale-105 focus:outline-none shadow-md hover:shadow-lg flex items-center space-x-2"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                  </svg>
                  <span>Download Resume PDF</span>
                </a>
                <button 
                  onClick={downloadCoverLetter}
                  className="bg-gradient-to-r from-blue-600 to-blue-700 hover:from-blue-500 hover:to-blue-600 text-white font-bold py-2.5 px-6 rounded-lg transition-all duration-200 hover:scale-105 focus:outline-none shadow-md hover:shadow-lg flex items-center space-x-2"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                  </svg>
                  <span>Download Cover Letter</span>
                </button>
              </div>
            </div>
          )}
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default ManualJobTailoring;